


import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components/macro";

const FileSPlitSkeleton = ({pdfLoaded,progress}) => {
  
  return (
    <div>
      {/* Header */}
      {/* <Skeleton height={50} /> */}

      {/* Main Content */}
      <div style={{ display: pdfLoaded?"flex" : "none", marginTop: "20px",position:'absolute',background:'white',zIndex:'1', }}>
        {/* Left Sidebar */}
        <div style={{ width: "20%", marginRight: "20px", marginLeft: "20px" }}>

          <div style={{ marginBottom: "30px" }}>
            <LeftContainerBox  >
              {/* <Skeleton height={300} width={482} /> */}
              <div className="headder-container">
                <Skeleton height={30} width={282} />
              </div>
              <div className="splited-pdf-boxes">
                <div className="single-left-box">
                  <Skeleton width={140} height={80} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={80} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={140} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={100} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={140} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={140} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={180} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={140} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={140} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={140} />
                </div>
                <div className="single-left-box">
                  <Skeleton width={140} height={140} />
                </div>
              </div>
            </LeftContainerBox>

          </div>

          {/* <div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
            <div>
              <Skeleton height={30} width={282} />
            </div>
          </div> */}
          {/* Add more sidebar items as needed */}
        </div>

        {/* Main Content Area */}
        <div style={{ width: "75%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            {/* Zoom and Rotate Buttons */}
            <ButtonIconConatiner>
              <div>
                <Skeleton circle height={36} width={36} />
              </div>
              <div>
                <Skeleton circle height={36} width={36} />
              </div>
              <div>
                <Skeleton circle height={36} width={36} />
              </div>
              <div>
                <Skeleton circle height={36} width={36} />
              </div>
              <div>
                <Skeleton circle height={36} width={36} />
              </div>
              <Skeleton height={30} width={60} />
              {/* Add more buttons as needed */}
            </ButtonIconConatiner>

            {/* Save Button */}
            {/* <div>
              <Skeleton circle height={36} width={36} />
            </div> */}
          </div>

          {/* Canvas Display */}
          <MainListContainer style={{ display: "flex", flexWrap: "wrap" }}>
            {/* Placeholder for multiple canvas elements */}
            <div>
              <Skeleton width={150} height={70} />

            </div>
            <div>
              <Skeleton width={150} height={70} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={100} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={180} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            <div>
              <Skeleton width={150} height={180} />

            </div>
            <div>
              <Skeleton width={150} height={140} />

            </div>
            {/* Add more placeholders as needed */}
     { parseInt(progress) < 100  ? <div class="loading loading03">
           <span>D</span>
           <span>O</span>
           <span>W</span>
           <span>N</span>
           <span>L</span>
           <span>O</span>
           <span>A</span>
           <span>D</span>
           <span>I</span>
           <span>N</span>
           <span>G</span>
           <span>.</span>
           <span>.</span>
           <span>.</span>
           <span>{parseInt(progress)}%</span>
               
           </div> : 
           <div class="loading loading03">
           <span>P</span>
           <span>R</span>
           <span>O</span>
           <span>C</span>
           <span>E</span>
           <span>S</span>
           <span>S</span>
           <span>I</span>
           <span>N</span>
           <span>G</span>
           <span>.</span>
           <span>.</span>
           <span>.</span>
           </div>

         }

          </MainListContainer>
        </div>
      </div>

      {/* SingleCanvas Overlay */}
      {/*
        The overlay is displayed when signleView.isOpen is true.
        You can add a skeleton or loading indicator here.
      */}
      {/*
        <BlurBackground>
          <SkeletonLoadingOverlay />
        </BlurBackground>
      */}
    </div>
  );
};

export default FileSPlitSkeleton;


const ButtonIconConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 20%;
  transition: all 0.4s ease-in-out;
  .save-submit{
    width: 36px;
    height: 36px;
  }
  div {
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    svg {
      font-size: 16px;
    }
  }
`;
const MainListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:'center';
    gap: 25px;
    div{
      // width: 200px;
      // height: 150px;
      // margin-right: 90px;
      // margin-bottom: 60px;
      
    }
`
const LeftContainerBox = styled.div`
  box-shadow: rgb(232, 232, 232) 0px 1px 2px 1px;
  border-radius: 5px;
  overflow-y: scroll;
  padding:10px;
  height: 88vh;
  .headder-container{
    display:flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .splited-pdf-boxes{
    display:flex;
    flex-wrap:wrap;
    gap:10px;
    align-items: center;
    justify-content: center;
    .single-left-box{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

`
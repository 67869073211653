import React, { useEffect } from 'react'
import userIcon from "../../../assets/img/d-users.png";
import fileSplitIcon from "../../../assets/img/file_split.png";
import facilitiesIcon from "../../../assets/img/facilities.png";
import clientIcon from "../../../assets/img/client.png";
import pendingIcon from "../../../assets/img/pending.png";
import totalJobsIcon from "../../../assets/img/total_jobs.png";
import wishIcon from "../../../assets/img/wish.png";
import missedTatIcon from "../../../assets/img/missed_tat.png";
import closeTatIcon from "../../../assets/img/close_tat.png";
import completedIcon from "../../../assets/img/completed.png";
import largeFileIcon from "../../../assets/img/file.svg";
import { Tooltip } from '@mui/material';
function AdminDashboardCard({ dashboardData,cardTotal }) {

    return (

        <div className="card-main-container">

            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div className=''>
                            <img src={userIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Active Users</h4>
                            <span className='dashboard-round-count'>
                                {dashboardData?.active_users}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={clientIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Clients</h4>
                            <span className='dashboard-round-count'>
                                {cardTotal?.client}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={facilitiesIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Facilities</h4>
                            <span className='dashboard-round-count'>
                                {cardTotal?.facility}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={closeTatIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Close To TAT</h4>
                            <span className='dashboard-round-count'>
                                {cardTotal?.close_to_tat}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={missedTatIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Missed TAT</h4>
                            <span className='dashboard-round-count add-color'>
                                {cardTotal?.missed_tat}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={fileSplitIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>File Split</h4>
                            {/* <span className='dashboard-round-count'>
                                {dashboardData?.file_split}
                            </span> */}
                            <div className='count-img-container'>
                                <Tooltip disableInteractive title={"File Split"} placement='top' arrow>
                                    <span className='dashboard-round-count'>
                                        {cardTotal?.split_files}
                                    </span>
                                </Tooltip>
                                {/* <Tooltip disableInteractive title={"Snippet"} placement='top' arrow>
                                    <div className='right-file-count-container'>

                                        <div className='file-img'>
                                            <img src={pendingIcon} />
                                        </div>
                                        <span>{dashboardData?.file_split?.snipping_jobs}</span>
                                    </div>
                                </Tooltip> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={largeFileIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Large File</h4>
                            <div className='count-img-container'>
                                <Tooltip disableInteractive title={"Large File"} placement='top' arrow>
                                    <span className='dashboard-round-count'>
                                        {dashboardData?.large_batch_files?.large_batch}
                                    </span>
                                </Tooltip>
                                <Tooltip disableInteractive title={"Sub Jobs"} placement='top' arrow>
                                    <div className='right-file-count-container'>
                                        <div className='file-img'>
                                            <img src={pendingIcon} />
                                        </div>
                                        <span>{dashboardData?.large_batch_files?.sub_job}</span>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={totalJobsIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Total Jobs</h4>
                            <span className='dashboard-round-count'>
                                {cardTotal?.total_jobs}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={pendingIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Pending</h4>

                            <div className='count-img-container'>
                                <Tooltip disableInteractive title={"Pending"} placement='top' arrow>
                                    <span className='dashboard-round-count'>
                                        {dashboardData?.peniding_job?.job_data}
                                    </span>
                                </Tooltip>
                                <Tooltip disableInteractive title={"Hold/Reject"} placement='top' arrow>
                                    <div className='right-file-count-container'>
                                        <div className='file-img'>
                                            <img src={pendingIcon} />
                                        </div>
                                        <span>{isNaN(dashboardData?.peniding_job?.hold_jobs + dashboardData?.peniding_job?.rejected_jobs) ? 0 : dashboardData?.peniding_job?.hold_jobs + dashboardData?.peniding_job?.rejected_jobs}</span>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div>
                            <img src={completedIcon} />
                        </div>
                        <div>
                            <h4 className='mb-2'>Completed</h4>
                            <span className='dashboard-round-count'>
                                {cardTotal?.completed_jobs}
                            </span>
                        </div>

                    </div>
                </div>
            </div>


        </div>


    )
}

export default AdminDashboardCard



import {
    CREATE_REJECT_REASON_URL,
    DELETE_REJECT_REASON_URL,
    GET_ALL_REJECT_REASON_URL,
    GET_ALL_SFTP_FILES_URL,
    GET_FILESPLIT_HISTORY_URL,
    GET_MANUAL_FILE_SPLIT_URL,
    GET_REJECT_REASON_DETAILS_URL,
    GET_SINGLE_MANUAL_FILE_SPLIT_URL,
    POST_MANUAL_FILE_SPLIT_URL,
    POST_REJECT_SFTP_FILE,
    POST_RELEASE_SPLIT_FILE,
    RELEASE_MANUAL_FILE_SPLIT_URL,
    REPLACE_MANUAL_FILE_SPLIT_URL
} from "../../api";
import axiosInstance from "../../axiosInstance";


export const getManualFileSplits = (props) => onResponse => {
    try {
        axiosInstance.get(GET_MANUAL_FILE_SPLIT_URL + "?search=" + props.search + "")
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getFileSplitHistory = (file_id) => onResponse => {
    try {
        axiosInstance.get(GET_FILESPLIT_HISTORY_URL + "?file_id=" + file_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getFileSplitFetchData = (props) => onResponse => {
    let search = props.name ? props.name : ""
    let clients = props.clients ? props.clients : ""
    let facility = props.facility ? props.facility : ""
    let status = props.status ? props.status : ""
    let type = props.type ? props.type : ""
    try {
        axiosInstance.get(GET_MANUAL_FILE_SPLIT_URL + "?page=" + props.page + "&limit=" + props.newPerPage + "&search=" + search + "&client=" + clients + "&facility=" + facility + "&status=" + status + "&type=" + type)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }
}

export const getSingleFile = (id) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_MANUAL_FILE_SPLIT_URL + "?file_id=" + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};// client 
export const postManualSplitFile = (props) => onResponse => {
    try {
        axiosInstance.post(POST_MANUAL_FILE_SPLIT_URL, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const releaseManualSplitFile = (id, reason) => onResponse => {
    try {
        axiosInstance.post(RELEASE_MANUAL_FILE_SPLIT_URL, { file_id: id, comment: reason })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const replaceManualSplitFile = (data) => onResponse => {
    try {
        axiosInstance.post(REPLACE_MANUAL_FILE_SPLIT_URL, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getAllSftpFiles = () => onResponse => {
    try {
        axiosInstance.post(GET_ALL_SFTP_FILES_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};



export const rejectSftpFile = (pk) => onResponse => {
    try {
        axiosInstance.post(POST_REJECT_SFTP_FILE, { pk })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};




export const getAllSftpFilesFilter = (filename, client, file_received_on) => onResponse => {
    try {

        let BASE_URI = `${GET_ALL_SFTP_FILES_URL}?`
        if (filename !== 'undefined' && filename !== '') {
            BASE_URI += `filename=${filename}&`
        }
        if (client !== 'undefined' && client !== '') {
            BASE_URI += `client=${client}&`
        }
        if (file_received_on !== 'undefined' && file_received_on !== '') {
            BASE_URI += `file_received_on=${file_received_on}&`
        }

        axiosInstance.get(BASE_URI)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllRejectReasons = (page, limit, search) => onResponse => {
    try {
        let data = search ? search : ''
        axiosInstance.get(GET_ALL_REJECT_REASON_URL + '?page=' + page + '&limit=' + limit + '&search=' + data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createRejectReason = (rejection, rejection_reason, reason_level, roles) => onResponse => {
    if (rejection) {

        try {
            axiosInstance.post(CREATE_REJECT_REASON_URL, { rejection, rejection_reason, reason_level, roles })
                .then((response) => {
                    onResponse(response.data);
                })
                .catch((err) => {
                    onResponse(err);
                });

        } catch (error) {

        }
    }
    else {
        try {
            axiosInstance.post(CREATE_REJECT_REASON_URL, { rejection_reason, reason_level, roles })
                .then((response) => {
                    onResponse(response.data);
                })
                .catch((err) => {
                    onResponse(err);
                });

        } catch (error) {

        }
    }

};
export const getRejectReasonDetails = (rejection) => onResponse => {
    try {
        axiosInstance.get(GET_REJECT_REASON_DETAILS_URL + '?rejection=' + rejection)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteRejectReason = (rejection) => onResponse => {
    try {
        axiosInstance.delete(DELETE_REJECT_REASON_URL, {
            data: { rejection: rejection }
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const releaseSplitFile = (file_id) => onResponse => {
    try {
        axiosInstance.post(POST_RELEASE_SPLIT_FILE, {
            file_id
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
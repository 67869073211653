import React from 'react'
import DataTable from 'react-data-table-component';
import { createTheme } from 'react-data-table-component';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import useAutocomplete from '@mui/base/useAutocomplete';
import styled from 'styled-components/macro';
import { useState } from 'react';
import TatDetailDataTable from './TatDetailDataTable';
import { useCallback } from 'react';
import { Tooltip } from "@mui/material";

const queueDatatableCustomStyles = {
    rows: {
        style: {
            minHeight: '20px', // override the row height
            fontSize: '1.1em',
            color: '#666666',
            backgroundColor: '#F0F5F6',
            borderBottom: '1px solid white !important'

            // width: '100%'

        },
    },

    headCells: {
        style: {
            backgroundColor: '#eff5f6',
            border: '1px solid #fff !important',
            borderBottom: '1px solid #fff !important',

            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            textTransform: "uppercase",
            fontSize: '0.9em',
            // fontWeight: '800',
            color: '#016b87 !important',
            opacity: 1,
            minHeight: "100px",

        },
        draggingStyle: {
            cursor: 'move',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
            fontColor: '#000000',
            fontWeight: "550",
            borderRight: '1px solid #fff !important',
        },
    },

};
// createTheme(
//     'solarized',
//     {
//         text: {
//             primary: '#000000',
//             secondary: 'rgba(0, 0, 0, 0.54)',
//             disabled: 'rgba(0, 0, 0, 0.38)',
//         },
//         background: {
//             default: '#FFFFFF',
//         },
//         context: {
//             background: '#e3f2fd',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//         divider: {
//             default: '#f4f4f4',
//         },
//         button: {
//             default: 'rgba(0,0,0,.54)',
//             focus: 'rgba(0,0,0,.12)',
//             hover: 'rgba(0,0,0,.12)',
//             disabled: 'rgba(0, 0, 0, .18)',
//         },
//         selected: {
//             default: '#e3f2fd',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//         highlightOnHover: {
//             default: '#EEEEEE',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//         striped: {
//             default: '#FAFAFA',
//             text: 'rgba(0, 0, 0, 0.87)',
//         },
//     }
// );

function ClientDetailDataTable(props) {
    const { dataId, data, copyData, detailExpandedRows, setDetailExpandedRows, setIsCopieValue } = props

    const [isFocusedStatus, setIsFocusedStatus] = useState(false);
    const [expandedRows, setExpandedRows] = useState(false);
    const ExpandedComponent = useCallback(
        ({ data }) => (
            <pre style={{ marginBottom: 'unset' }}>
                {detailExpandedRows[dataId]?.length > 0 && (
                    <TatDetailDataTable setIsCopieValue={setIsCopieValue} data={data?.tat}
                    />
                )}
            </pre>
        ),
        [detailExpandedRows[dataId]]
    );
    const handleRowExpand = useCallback((row) => {
        const test = detailExpandedRows && detailExpandedRows[dataId] ? detailExpandedRows[dataId] : []
        const isRowExpanded = detailExpandedRows && detailExpandedRows[dataId] && detailExpandedRows[dataId].find((i) => i?.id === row?.id) ? true : false;

        test.push(row)
        // const testvariable = detailExpandedRows
        detailExpandedRows[dataId] = isRowExpanded ? test.filter(i => i.id != row.id) : test


        setDetailExpandedRows(detailExpandedRows);
        setExpandedRows(!expandedRows)
    });


    const facilityTotal = (data) => {
        const totals = {
            close_to_tat: 0,
            completed_jobs: 0,
            missed_tat: 0,
            my_work: 0,
            pending_jobs: 0,
            qa_jobs: 0,
            split_files: 0,
            total_jobs: 0,
            my_work_pending_jobs: 0,
            qa_review_pending_jobs: 0,
            snipping_jobs: 0,
            snipping_pending_jobs: 0
        };
        data.tat.forEach(tat => {
            totals.close_to_tat += tat.close_to_tat;
            totals.completed_jobs += tat.completed_jobs;
            totals.missed_tat += tat.missed_tat;
            totals.my_work += tat.my_work;
            totals.pending_jobs += tat.pending_jobs;
            totals.qa_jobs += tat.qa_jobs;
            totals.split_files += tat.split_files;
            totals.total_jobs += tat.total_jobs;
            totals.my_work_pending_jobs += tat.my_work_pending_jobs;
            totals.qa_review_pending_jobs += tat.qa_review_pending_jobs;
            totals.snipping_jobs += tat.snipping_jobs;
            totals.snipping_pending_jobs += tat.snipping_pending_jobs;
        });

        return totals
    }

    const status = [
        { value: "1", label: "My Work" },
        { value: "2", label: "QA Review" },
    ]
    const columns = [
        {
            name: '',
            selector: row => "",
            sortable: false,
            // width: '149px'
            width: '17%'

        },
        {
            name: (
                <div style={{ width: '100%' }}>
                    <div className='mb-3'>Client</div>
                    <div>
                        <Select
                            options={status}
                            className=""
                            classNamePrefix="select"
                            placeholder="Status"
                            menuPlacement="auto"
                            maxMenuHeight={300}
                            // isClearable
                            name="status"
                            onFocus={() => setIsFocusedStatus(true)}
                            onBlur={() => setIsFocusedStatus(false)}
                            styles={{
                                container: (styles, state) => ({
                                    ...styles,
                                    borderRadius: "15px",
                                }),
                                control: (styles, state) => ({
                                    ...styles,
                                    backgroundColor: "#fff",
                                    borderStyle: "none", boxShadow: "none",
                                    height: "26px",
                                    minHeight: "0px",
                                    borderRadius: "7px",
                                    maxHeight: state.isFocused ? "auto" : "30px",
                                }),
                                indicatorsContainer: styles => ({
                                    ...styles,
                                    height: "28px",
                                }),
                                indicatorSeparator: styles => ({
                                    ...styles, width: "0px", minHeight: "20px"
                                }),
                                valueContainer: (styles, state) => ({
                                    ...styles,
                                    marginBottom: "89px",
                                    maxHeight: "30px",
                                }),
                                option: (styles, { isFocused }) => ({
                                    ...styles,
                                    padding: "6px 12px", // Adjust the padding as per your preference
                                    fontSize: '13px'
                                }),
                            }}
                            menuPortalTarget={document.body}
                        />
                    </div>

                </div>
            ),
            selector: row => row.name,
            cell: (row) => <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "230px" }} onClick={() => handleRowExpand(row)}>
                    <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                        <span className="text-truncate"> {row?.name}</span>
                    </Tooltip>
                </div>
                <span className='dashboard-detail-plus-icon' onClick={() => { handleRowExpand(row) }}>
                    {detailExpandedRows && detailExpandedRows[dataId] && detailExpandedRows[dataId].find((i) => i?.id === row?.id) ?

                        <i class="bi bi-dash"></i>
                        :
                        <i class="bi bi-plus"></i>
                    }
                </span>

            </div>,
            // width: '149px',
            width: '13%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontSize: '1.1em',
                    },
                },


            ],
            sortable: false,
        },
        {
            name: "",
            selector: row => row.tat_datetime,
            sortable: false,
            width: '11%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],

        },
        {
            name: 'CLOSED TO TAT',
            selector: row => facilityTotal(row)?.close_to_tat,
            sortable: false,
            right: true,
            width: '7%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'Missed TAT',
            selector: row => facilityTotal(row)?.missed_tat,
            sortable: false,
            right: true,
            width: '6.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'File SPlit',
            selector: row => facilityTotal(row)?.split_files,
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'Total Jobs',
            selector: row => facilityTotal(row)?.total_jobs,
            sortable: false,
            right: true,
            width: '6%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'IMPORTED',
            selector: row => facilityTotal(row)?.my_work_pending_jobs,
            sortable: false,
            right: true,
            width: '5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'My Work',
            selector: row => facilityTotal(row)?.my_work,
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'QA Review',
            selector: row => facilityTotal(row)?.qa_review_pending_jobs,
            sortable: false,
            right: true,
            width: '7%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'QA',
            selector: row => facilityTotal(row)?.qa_jobs,
            sortable: false,
            right: true,
            width: '4.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        // {
        //     name: 'Snipping',
        //     selector: row => facilityTotal(row)?.snipping_jobs,
        //     sortable: false,
        //     right: true,
        //     width: '5%',
        //     conditionalCellStyles: [
        //         {
        //             when: row => row,
        //             style: {
        //                 fontWeight: '900'
        //             },
        //         },


        //     ],
        // },
        // {
        //     name: 'SN Inprocess',
        //     selector: row => facilityTotal(row)?.snipping_pending_jobs,
        //     sortable: false,
        //     right: true,
        //     width: '6%',
        //     conditionalCellStyles: [
        //         {
        //             when: row => row,
        //             style: {
        //                 fontWeight: '900'
        //             },
        //         },


        //     ],
        // },
        {
            name: 'Pending',
            selector: row => facilityTotal(row)?.pending_jobs,
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'Completed',
            selector: row => facilityTotal(row)?.completed_jobs,
            sortable: false,
            right: true,
            width: '6.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
    ];
    return (
        <DataTable
            noTableHead={true}
            columns={columns}
            data={data}
            customStyles={queueDatatableCustomStyles}

            expandableRows
            expandOnRowClicked={false}
            onRowExpandToggled={handleRowExpand}
            expandableRowsComponent={ExpandedComponent}
            expandableRowsHideExpander
            expandableRowExpanded={(row) => detailExpandedRows && detailExpandedRows[dataId] && detailExpandedRows[dataId].find((i) => i?.id == row?.id) ? true : false}
        // theme="solarized"

        />
    )
}

export default ClientDetailDataTable




export const INDEX = "/"

export const HOME = "/home"

export const LOGIN = "/login"

// admin routes
export const DASHBOARD = "/admin/dashboard"
export const DUMMY_DASHBOARD = "/admin/dummy-dashboard"

// iframe
export const IFRAME = "/xtract"

// Keep Alive
export const KEEP_ALIVE = "/auth/update-user-activity"

//roles
export const ROLES = "/view/roles"
export const CREATE_ROLES = "/roles/create"
export const EDIT_ROLES = "/edit-role/"

//groups
export const GROUPS = "/view/groups"
export const CREATE_GROUPS = "/groups/create"
export const EDIT_GROUPS = "/edit-group/"

//users
export const USERS = "/view/users"
export const CREATE_USERS = "/users/create"
export const EDIT_USERS = "/edit-user/"

//payee
export const PAYEE = "/view/payees"
export const CREATE_PAYEE = "/payee/create"
export const EDIT_PAYEE = "/edit-payee/"
export const APPROVE_PAYEE = "/edit-payee/"

//payer
export const PAYER = "/view/payers"
export const CREATE_PAYER = "/payer/create"
export const EDIT_PAYER = "/edit-payer/"
//payer id list
export const PAYER_ID_LIST = "/view/payer-id-list"
export const CREATE_PAYER_ID_LIST = "/payer/create-id-list"
export const EDIT_PAYER_ID_LIST = "/edit-payer-id-list/"

//data-entry
export const DATA_ENTRY = "/my-work"
export const QA_REVIEW = "/qa-review"
export const EDIT_WORK = "/my-work/edit/"
export const SNIPPET = "/view-snippet"

//clients
export const CLIENT = "/view/clients"
export const CREATE_CLIENT = "/clients/create"
export const EDIT_CLIENT = "/clients/edit/"
export const COPY_CLIENT = "/clients/copy/"

//client-groups
export const CLIENT_GROUP = "/view/client-groups"
export const CREATE_CLIENT_GROUP = "/client-groups/create"
export const EDIT_CLIENT_GROUP = "/client-groups/edit/:id"
export const CREATE_ASSIGN_USERS = "/assign-users/create/:id"
export const SINGLE_ASSIGN_GROUP_USERS = "client/add-staff-to/get-staffs-in-client-group"

//sftp-pull
export const SFTP_CLIENT_LIST = "/view/sftp-client-list"

//sftp-log
export const SFTP_LOG_LIST = "/view/sftp-log-list"
//house-keeping-log
export const HOUSE_KEEPING_LOG_LIST = "/view/house-keeping-log-list"
//email-log
export const EMAIL_LOG_LIST = "/view/email-log-list"

//file-split
export const FILE_SPLIT = "/view/file-split"
export const SINGLE_SPLIT = "/edit/split/"
export const OLD_SINGLE_SPLIT = "/edit/old-split/"

//lockbox
export const LOCKBOXFILES = "/all/lockbox-files"
export const SINGLE_LOCKBOX_LOCKBOX_FILES = "/single-lockbox/file/"
export const LOCKBOX = "/all/lockbox"


//reason codes
export const REASON_CODES = "/view/reason-codes"
export const CREATE_REASON_CODES = "/create/reason-codes"
export const EDIT_REASON_CODES = "/create/reason-codes/"

//X12 codes
export const X12_CODES = "/view/x12-codes"
export const CREATE_X12_CODES = "/create/x12-codes"
export const EDIT_X12_CODES = "/edit/x12-code/"

//correspondence codes
export const CORRESPONDENCE_CODES = "/view/correspondence-categories"
export const CREATE_CORRESPONDENCE_CODE = "/create/correspondence-category"
export const EDIT_CORRESPONDENCE_CODE = "/edit/correspondence-category/"

//facility
export const FACILITIES = "/view/facilities"

//reject reason routes
export const REJECT_REASONS = "/view/reject-reasons"
export const CREATE_REJECT_REASONS = "/create/reject-reasons"
export const EDIT_REJECT_REASONS = "/edit/reject-reasons/"

//holiday calendar
export const HOLIDAY_CALENDAR = "/view/holiday-calendar"
export const CREATE_HOLIDAY_CALENDAR = "/create/holiday-calendar"
export const EDIT_HOLIDAY_CALENDAR = "/edit-holiday-calendar/"

//settings
export const SETTINGS = "/view/settings"

//offline status
export const OFFLINE_STATUS = "/view/offline-status"


//QMS 
export const QMS = "/job-monitor"
export const LOCKBOX_FILES_QMS = "/job-monitor/"
export const VIEW_LOG_DETAIL = "/view/log-detail/"
export const QMSQACOMPlETED = "/queue/qa/completed"


// OutputSchema
export const GET_PATIENT_PAY = '/payment/get-patient-pay'
export const GET_CORRESPONDANCE = '/payment/get-correspondence'
export const GET_ENCOUNTER_LIST = '/encounter/get-encounter-list'
export const GET_835_LIST = '/payment/get-output-file-schema'
export const GET_835_LITE_LIST = '/payment/get-835-lite-schema'
export const GET_ACKNOWLEDGMENT_LIST = '/payment/get-acknowledgment-list'
export const GET_REPORT_LIST = '/payment/get-report-list'

//permissions route
export const PERMISSION_LIST = '/users/get-permissions'

// Notification List
export const NOTIFICATION_LIST = '/notifications'
export const NOTIFICATION_EDIT = '/notification/edit/'

// Partner List
export const PARTNER_LIST = '/view/partner'
export const PARTNER_CREATE = '/create/partner'
export const PARTNER_EDIT = '/edit/partner/'


//
export const DOCUMENT_CLASSIFICATION = "/document-classification"
export const CREATE_DOCUMENT = "/create/document"
export const EDIT_DOCUMENT = "/edit/document/"
export const DELETE_DOCUMENT_CLASSIFICATION = "/organization/document-classifications/activate-or-deactivate-document-classification"


export const REASON = "/reason"
export const CREATE_REASON = "/create/reason"
export const EDIT_REASON = "/edit/reason/"
export const DELETE_INCOMPLETE_STATUS = '/organization/file-incomplete-status/activate-or-deactivate-incomplete-status'

// CLAIMS FILING
export const CREATE_CLAIMS_FILING = '/create/claims-filing'
export const EDIT_CLAIMS_FILING = "/edit/claims-filing/"
//export const DELETE_CLAIMS_FILING = "/organization/claim-filling-indicator-codes/activate-or-deactivate-claim-indicator-codes"
export const CLAIMS_FILING = "/claims-filing"

// EMAIL TEMPLATE
export const EMAIL_TEMPLATE = "/email-template"
export const CREATE_EMAIL_TEMPLATE = '/create/email-template'
export const EDIT_EMAIL_TEMPLATE = "/edit/email-template/"

export const DBK_LIST = '/view/dbk'
export const CREATE_DBK = '/create/view/dbk'
export const EDIT_DBK = '/edit/view/dbk/'




export const RETRIVE_SPRING_LOCKBOX = "/retrive/spring-lockboxes"
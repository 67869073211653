// DASHBOARD ROUTES
export const GET_DASHBOARD_USER_DATA_URL = "/dashboard/dashboard-view";
// export const GET_DASHBOARD_GRID_DATA_URL = "/dashboard/dashboard-first";
// export const GET_DASHBOARD_REPORT_DATA_URL = "/dashboard/dashboard-client-report";
export const GET_DASHBOARD_GRID_DATA_URL = "/dashboard/v2/get-dashboard-report-count";
export const GET_DASHBOARD_REPORT_DATA_URL = "/dashboard/v2/get-dashboard-reports";

// LOGIN ROUTES
export const LOGIN_URL = "/auth/login";
export const REFRESH_TOKEN_URL = "/auth/token/refresh/";
export const SESSION_LOGOUT = "/auth/logout";

//keep alive
export const KEEP_ALIVE_URL = "/reports/keep-alive";

// Header
export const GET_TIMEZONE_URL = "/users/user-timezone-update"

//user managment
export const GET_GROUPS_URL = "/users/groups/get-groups";
export const CREATE_GROUPS_URL = "/users/groups/create-or-update-group";
export const GET_GROUP_DETAILS_URL = "/users/groups/get-group-details";
export const DELETE_GROUP_URL = "/users/groups/destroy-group";


// User Management Roles
export const GET_ROLES_URL = "/users/roles/get-roles";
export const GET_ROLES_DROPDOWN_URL = "/auth/roles-dropdown";
export const CREATE_ROLES_URL = "/users/roles/create-or-update-role";
export const DELETE_ROLES_URL = "/users/roles/destroy-role";
export const GET_SINGLE_ROLE_DETAIL_URL = "/users/roles/get-role-details";


//User Magement Permissions
export const GET_PERMISSIONS_URL = "users/get-permissions";

//payee managment
export const CREATE_PAYEE_URL = "/organization/payee/create-or-update-payee";
export const GET_ALL_PAYEE_URL = "/organization/payee/get-payee";
export const TOGGLE_PAYEE_URL = "/organization/payee/activate-or-deactivate-payee";
export const GET_SINGLE_PAYEE_DETAIL_URL = "/organization/payee/get-payee-details";
export const GET_PAYEE_CSV_IMPORT_URL = "organization/payee/payee-csv-upload"
export const EXPORT_PAYEE_URL = "/organization/payee/payee-export";
export const GET_PAYEE_DEFFERENCE_REPORT_URL = "organization/payee/get-payee-hostory"

//payer managment
export const CREATE_PAYER_URL = "/organization/payer/create-or-update-payer";
export const GET_ALL_PAYER_URL = "/organization/payer/get-payer";
export const TOGGLE_PAYER_URL = "/organization/payer/activate-or-deactivate-payer";
export const GET_SINGLE_PAYER_DETAIL_URL = "/organization/payer/get-payer-details";
export const GET_PAYER_CSV_IMPORT_URL = "organization/payer/payer-csv-upload"
export const GET_PAYER_DEFFERENCE_REPORT_URL = "organization/payer/get-payer-hostory"
export const EXPORT_PAYER_URL = "/organization/payer/payer-export";
export const GET_DEFAULT_PAYEE_PAYER_URL = "/organization/payer/get-payer-payee-default";

//payer id list
export const CREATE_PAYER_ID_LIST_URL = "/organization/availity-payer-list/create-or-update-availity-payer-list";
export const GET_ALL_PAYER_ID_LIST_URL = "/organization/availity-payer-list/get-availity-payer-list";
export const GET_PAYER_ID_LIST_CSV_IMPORT_URL = "organization/availity-payer-list/availity-payer-list-csv-upload"
export const EXPORT_PAYER_ID_LIST_URL = "/organization/availity-payer-list/availity-payer-list-export";
export const GET_SINGLE_PAYER_ID_LIST_DETAIL_URL = "/organization/availity-payer-list/get-availity-payer-list-details";
export const DELETE_PAYER_ID_LIST_URL = "/organization/availity-payer-list/destroy";


//user managment
export const CREATE_USER_URL = "/users/create-or-update-user";
export const GET_ALL_USER_URL = "/users/get-users";
export const TOGGLE_USER_URL = "/users/activate-or-deactivate-users";
export const GET_SINGLE_USER_DETAIL_URL = "/users/get-user-details";
export const GET_GROUPS_FOR_USER_URL = "/users/get-groups-for-user-creation";
export const GET_USER_CSV_IMPORT_URL = 'users/user-csv-upload'

//client group
export const CREATE_CLIENT_GROUP_URL = "client/client-group/client-group-create-update";
export const GET_ALL_CLIENT_GROUP_URL = "client/client-group/get-client-group-all";
export const GET_CLIENT_GROUP_DETAILS_URL = "client/client-group/client-group-single-view";
export const DESTROY_CLIENT_GROUP_URL = "client/client-group/destroy-client-group";
export const GET_ALL_STAFF_URL = "users/staff/staff-name-choice-listing";

export const CREATE_ASSIGN_USERS_URL = "client/add-staff-to/add-staff-client-group-create-update";


//client managment

export const GET_CLIENT_URL = "/client/client-management/get-clients";
export const GET_PAYER_DROPDOWN = "/organization/payer/get-payer-dropdown";
export const GET_PAYER_DROPDOWNDEBOUNCE = "/organization/payer/get-payer-dropdown";

export const GET_PAYER_DROPDOWN_V2 = "/organization/v2/payer/search-payer-dropdown";
export const TOGGLE_CLIENT_URL = "/client/client-management/enable-or-disable-client";
export const CREATE_CLIENT_URL = "/client/client-management/create-or-update-client";
export const CREATE_CAIM_URL = "/client/create-or-update-claims";
export const CREATE_TAT_URL = "/client/tat/create-or-update-tat";
export const CREATE_CLIENT_PAYER_URL = "/client/payer-create-or-update";
export const CREATE_CONTACT_PAYER_URL = "/client/create-or-update-contact";
export const CREATE_INBOUND_URL = "/client/inbound/create-or-update-inbound";
export const CREATE_OUTBOUND_URL = "/client/create-or-update-outbound";
export const CREATE_DEFAULT_URL = "/client/create-or-update-defaults";
export const CREATE_DOCUMENTTYPES_URL = "/client/documenttype/create-or-update-documenttype";
export const GET_BLACKLISTPAYER_URL = "/client/get-blacklisting-payers";
export const CREATE_BLACKLISTPAYER_URL = "/client/blacklisting-payers";
export const CREATE_DBK_URL = "/client/create-or-update-dbk";
export const CREATE_OPERATION_LOG_URL = "/client/v2/onboard/create-or-update-operation-log-metadata";
export const GET_CLIENTS_OPTIONS_URL = "/client/documenttype/get-client-dropdown";
export const CREATE_CLIENTS_FACILITY_URL = "/client/create-or-update-facility";
export const GET_CLIENTS_DETAILS_URL = "/client/client-management/get-clients-for-edit";
export const GET_CLIENTS_PDF_URL = "/client/pdf-generate-api-call";
export const GET_CLIENTS_GROUPS_CHOICE_URL = "/client/client-group/get-client-group-choice";
export const GET_CLIENTS_DROPDOWN_LISTS_URL = "/client/client-management/get-client-dropdown-listing";
export const SYNC_TO_REVARC_URL = "/revarc_sync/retrieve-data-for-revarc-sync";
export const CREATE_OR_UPDATE_ADVANCE_CUSTOMISATION = "/client/create-or-update-advance-customisation";
export const CREATE_OR_UPDATE_SNIPPET = "/client/create-or-update-snipping-customisation";
export const CREATE_OR_UPDATE_REVARC_CUSTOMISATION = "/client/create-or-update-revarc-customisation";



//Reason code mngmt
export const GET_REASON_CODES_URL = "/reasoncodes/get-reason-codes";
export const CREATE_REASON_CODES_URL = "/reasoncodes/reason-codes/create-or-update";
export const DELETE_REASON_CODES_URL = "/reasoncodes/reason-codes/destroy";
export const GET_SINGLE_REASON_CODE_URL = "/reasoncodes/get-single-reasoncodes-detail-view";
export const GET_REASON_CODES_DROPDOWN_URL = "/reasoncodes/get-reasoncodes-dropdown";
export const POST_PROP_CODE_CREATE_URL = "/reasoncodes/prop-codes/create-or-update";
export const GET_CLIENT_FACILITY_URL = "/client/get-facility-dropdown";
export const EXPORT_REASON_CODES_URL = "/reasoncodes/reasoncode-export";


//X12 Code managment
export const GET_X12_CODES_URL = "/x12codes/v2/get-x12-codes";
export const CREATE_X12_CODE_URL = "/x12codes/v2/x12-codes/create-or-update";
export const TOGGLE_X12_CODE_URL = "/x12codes/x12-codes/change-status";
export const GET_X12_CODE_DROPDOWN_URL = "/x12codes/get-x12codes-dropdown";
export const GET_SINGLE_X12_CODE_DROPDOWN_URL = "/x12codes/get-x12-details";

//Correspondence Category managment
export const GET_CORRESPONDENCE_CATEGORY = "/organization/correspondence-category/get-corresponden/cecategory";
export const CREATE_CORRESPONDENCE_CATEGORY = "/organization/correspondence-category/create-or-update-correspondence-category";
export const DELETE_CORRESPONDENCE_CATEGORY = "/organization/correspondence-category/destroy-CorrespondenceCategory";
export const GET_SINGLE_CORRESPONDENCE_CATEGORY = "/organization/correspondence-category/single-detail-view";
export const GET_CORRESPONDENCE_CATEGORY_DROPDOWN = "/organization/correspondence-category/get-correspondence-category-dropdown";

//Grid
export const GET_PAYER_LOOKUP_TABLE_URL = "/lookup/v1/payer-lookup";
export const GET_PAYEE_LOOKUP_TABLE_URL = "/lookup/v1/payee-lookup";
export const GET_SEARCH_837_FILE_URL = "/lookup/v1/encounter-lookup";

export const GET_SEARCH_837_FILE_ALL_URL = "/encounter/search-837-all-Search";
export const POST_CREATE_UPDATE_835_LITE_URL = "/grid/create-or-update-payment-lite";
export const POST_CREATE_UPDATE_CORRES_URL = "/grid/create-or-update-correspondance";
export const POST_CREATE_UPDATE_PAYMENT835_URL = "/grid/create-or-update-payment";
export const POST_CREATE_UPDATE_PATIENT_PAY_URL = "/grid/create-or-update-patient-pay";
export const GET_GRID_JOB_DETAILS = "/grid/work-allocation";
export const GET_QA_REVIEW_URL = "/client/assign-work-for-qa";
export const GET_V2_QA_REVIEW_URL = "/grid/v2/reassign-job-to-my-review";
export const GET_EDIT_WORK_URL = "/payment/payement-data-retrieving";
export const POST_VERIFY_PAYER_RC_URL = "/reasoncodes/payer-reason-code-verification";
export const REJECT_FILE_URL = "/queue/job-rejection/reject-file-or-job";
export const REJECT_JOB_URL = "/lockbox_explorer/lockbox-files/v2/reject-sftp-lockbox-file";
export const CHECK_EXCLUDED_PAYER_URL = "/client/check-exclude-payers";
export const JOB_STAFF_STATUS_URL = "/client/job-staff-is-online";
export const ASSIGN_FROM_QA_URL = "/client/change-from-qa-review";
export const POST_GRID_EXIT_URL = "/grid/grid-exit";
export const POST_GRID_JOB_TIMEOUT = "grid/job-timeout";
export const GET_SUB_JOB_USER_DATA = "/grid/get-sub-jobs";
export const UPDATE_JOB_USER_START_TIME = "/grid/update-job-user-start-time";
export const VERIFICATION_JOB_USER = "/grid/verification-job-user";
export const GET_TAXID_DATA = "/grid/get-tax";
export const GET_NPI_DATA = "/grid/get-npi";
export const POST_CREATE_UPDATE_DRAFT_URL = "/grid/drafts/grid-content";



//holiday-calendar
export const GET_HOLIDAY_CALENDAR_URL = "/holidaycalendar/get-holiday-calendars";
export const TOGGLE_HOLIDAY_CALENDAR_URL = "/holidaycalendar/holiday-calendar/change-status";
export const CREATE_HOLIDAY_CALENDAR_URL = "/holidaycalendar/holiday-calendar/create-or-update";
export const GET_NEW_SINGLE_HOLIDAY_CALENDER_URL = "holidaycalendar/holiday-calendar/single-holiday-detail-view"
//Settings
export const GET_ALL_SETTINGS_URL = "/settings/get-settings-details";
export const UPDATE_SETTINGS_URL = "/settings/create-or-update-settings";
export const GET_ALL_TIMEZONE = "/timezones/get-timezones"
export const GET_SELECTED_TIMEZONE = "/timezones/get-select-user-timezones"
export const GET_ALL_SETTINGS_DROP_DOWN_URL = "/settings/retrieve-default-config-values"

//Queue
export const GET_CHANGE_JOB_TAT_URL = "/client/change-job-tat";
export const GET_QUEUE_LISTING_URL = "/client/get-queue-listing";
export const GET_V2_QUEUE_LISTING_URL = "/client/v2/job-monitor/get-job-monitor";
export const GET_TOTAL_QUEUE_URL = "/client/get-total-job-monitor-values";
export const GET_USERLIST_DROPDOWN_URL = "/users/get-jobassigned-user-dropdown";
export const GET_USER_MANUAL_ASSIGN_URL = "/client/manual-job-assigning";
export const QUEUE_RELEASE_STATUS_CHANGE = "/client/queue-status-change";
export const QUEUE_V2_RELEASE_STATUS_CHANGE = "/client/v2/job-monitor/multiple-job-status-change";
export const GET_MY_WORK_DETAIL_REPORT_URL = "/reports/get-mywork-detail-report";
export const QUEUE_HOLD_USER = "/client/job-hold";
export const QUEUE_V2_HOLD_USER = "/client/v2/job-monitor/hold-multiple-job";
export const GENERATE_OUTPUT_SCHEMA_URL = "/payment/generate-output-file-schema";
export const GENERATE_V2_OUTPUT_SCHEMA_URL = "/payment/v2/bulk-generate-output-file-schema";
export const GENERATE_OUTPUT_XML_SCHEMA_URL = "/payment/generate-mywork-xml-file-schema";
export const RESENT_OUTPUT_SCHEMA_URL = "/payment/resend-output-file-schema";
export const CLIENT_JOB_HISTORY_URL = "/client/get-job-history";
export const POST_REJECT_SFTP_FILE = "/client/reject-sftp-file";
export const GENERATE_OPERATION_LOG = "/payment/v2/schedule-operation-log-generation";
export const GENERATE_OUTPUT_FILE_SCHEMA = "/payment/v2/schedule-output-generation";
export const EXPORT_JOB_MONITOR_URL = "/client/v2/job-monitor/export-job-monitor";
export const POST_LOCKBOX_COMMENT_URL = "/client/v2/file-management/lockbox/lockbox-comment";
export const GET_REPORTS_LOCKBOX_LOG_DETAILS = "/reports/get-lockbox-log-details";
export const GENERATE_LOCKBOX_REPORTS = "/payment/v2/bulk-generate-reports";



//file split
export const GET_FILESPLIT_HISTORY_URL = "/file-split/v2/manual-file-history";
export const RELEASE_MANUAL_FILE_SPLIT_URL = "/file-split/v2/release-file-split";
export const REPLACE_MANUAL_FILE_SPLIT_URL = "file-split/v2/replace-file";
export const GET_MANUAL_FILE_SPLIT_URL = "/file-split/v2/get-manual-split-files";
export const GET_SINGLE_MANUAL_FILE_SPLIT_URL = "/file-split/v2/get-manual-file-split-details";
export const POST_MANUAL_FILE_SPLIT_URL = "/file-split/v2/manuel-pdf-split";
export const GET_ALL_SFTP_SPLITTED_FILES_URL = "/client/all-sftp-files";
export const GET_ALL_SFTP_FILES_URL = "/client/get-eob-sftp-lockboxes";
export const TOGGLE_LOCKBOX_URL = "/client/v2/file-management/lockbox/activate-or-deactivate";
export const RETRIVE_OUTPUT_GENERATION_LOG_URL = "/payment/v2/retrive-output-generation-log";
// export const GET_ALL_SFTP_SPLITTED_FILES_URL = "/client/all-sftp-splitted-files";
export const REJECT_JOB_API = "/lockbox_explorer/job-monitor/v2/reject-jobs";
// dashboard
export const GET_USER_DASHBOARD_DAILY_DATA = "dashboard/get-user-files-daily-data"
export const GET_DASHBOARD_LOGGED_STAFF_LIST = "dashboard/get-loged-staff-list"
export const GET_DASHBOARD_CLIENT_FILE_PROGRESS = "dashboard/get-client-file-progress"


// Reject Reason
export const GET_ALL_REJECT_REASON_URL = "queue/file-rejection/get-file-rejection-all"
export const CREATE_REJECT_REASON_URL = "queue/file-rejection/file-rejection-create-update"
export const GET_REJECT_REASON_DETAILS_URL = "queue/file-rejection/file-rejection-single-view"
export const GET_REASON_CODE_CSV_IMPORT_URL = "reasoncodes/csv-import-reason-code"
export const DELETE_REJECT_REASON_URL = "queue/file-rejection/destroy-file-rejection"

// OutputSchema
export const GET_PATIENT_PAY_URL = '/payment/get-patient-pay'
export const GET_CORRESPONDANCE_URL = '/payment/get-correspondence'
export const GET_ENCOUNTER_LIST_URL = '/encounter/get-837-files'
export const GET_835_LIST_URL = '/payment/get-output-file-schema'
export const GET_835_LITE_LIST_URL = '/payment/get-output-xml-file-schema'
export const GET_V2_RESEND_OUTPUT_FILE_URL = '/payment/v2/transfer-outputs-to-ftp-server'
export const GET_ACKNOWLEDGMENT_LIST_URL = '/sftp/v2/get-acknowledgments'
export const RESEND_ACKNOWLEDGMENT_LIST_URL = '/sftp/v2/resend-acknowledgments-files'
export const GET_REPORT_LIST_URL = '/payment/v2/retrive-generated-reports'
export const SEND_DISPATCH_REPORT_URL = '/payment/v2/dispatch-reports'

//SFTP Client 
export const SFTP_CLIENT_LIST_URL = "/client/get-anytime-sftp-client-list"
export const PULL_SFTP_FILE_MANUALLY_URL = "/client/manual-file-split/fetch-files-from-server"
export const PULL_SFTP_FILE_MANUALLY_V2_URL = "/sftp/v2/sftp-file-fetching"
export const PULL_SFTP_FILE_MANUALLY_V3_URL = "/sftp/v2/sftp-file-manager"
export const RESET_SFTP_FILE_MANUALLY_V3_URL = "/lockbox_explorer/lockbox/v1/reset-file-retrieval-status"

export const POST_RELEASE_SPLIT_FILE = "/file-split/v2/release-manual-file-split"

//SFTP Log 
export const SFTP_LOG_LIST_URL = "/sftp/v2/get-log-jobs-file"
//House Keeping Log 
export const HOUSE_KEEPING_LOG_LIST_URL = "/sftp/v2/get-house-keeping-log"
//Email Log 
export const EMAIL_LOG_LIST_URL = "/sftp/v2/get-email-log-file"

//offline status
export const GET_OFFLINE_STATUS_JOBS_URL = "/queue/offline-jobs/get-offline-jobs"
export const GET_TOGGLE_OFFLINE_STATUS_URL = "/queue/offline-jobs/enable-disable-offline-jobs"
export const GET_OFFLINE_JOBS_LOG_HISTORY_URL = "/queue/offline-jobs/offlinejobs-log-history"
export const GET_REJECT_HISTORY_URL = "/lockbox_explorer/lockbox-files/v2/get-sftp-reject-or-split-history"

//Forgot Password 
export const FORGOT_PASSWORD_URL = "/users/reset-password-by-user"

export const CHANGE_PASSWORD_URL = "/users/reset-user-password-by-admin"

// Group & Permissions
export const GET_GROUP_PERMISSION_LIST = "/auth/permission-list"

// Payer Approve
export const PAYER_APPROVE_URL = "/organization/payer/payer-approve"

// Payee Approve
export const PAYEE_APPROVE_URL = "/organization/payee/payee-approve"

// ReasonCodes Approve
export const RESON_CODES_APPROVE_URL = "/reasoncodes/reason-code-approve"

//permissions route
export const PERMISSION_URL = "/auth/permission-list"

//firebase
export const GET_DEVICE_DETAILS = "/users/device-registration"

// notification
export const GET_NOTIFICATION_LIST_URL = "/notification/get-notification-count"
export const GET_NOTIFICATION_MARK_ALL_AS_READ_URL = "/notification/update-notification-status"
export const GET_NOTIFICATION_SINGLE_DETAIL_URL = "/notification/get-single-notifications-detail-view"

// partners
export const GET_PARTNERS_LIST_URL = "/partner/get-partners"
export const GET_PARTNER_DROPDOWN_URL = "/partner/get-partner-dropdown"
export const GET_PARTNER_SINGLE_DETAIL_URL = "/partner/get-partner-detail-view"
export const POST_CREATE_OR_UPDATE_URL = "/partner/create-or-update-partner"

export const GET_CLIENT_JOB_DEATIL_URL = "/client/job-details-api"

export const GET_MYWORK_DATA_ENTRY_REPORT_URL = "/reports/get-mywork-data-entry-difference-report"
export const GET_CLIENT_DEFFERENCE_REPORT_URL = "/reports/v2/get-client-report"
export const GET_USER_DIFFERENCE_REPORT_URL = "/reports/v2/get-user-report"

// document
export const GET_DOCUMENT_CLASSIFICAITON_URL = "/organization/document-classifications/get-document-classifications"
export const POST_CREATE_UPDATE_DOCUMENT_CLASSIFICAITON_URL = "/organization/document-classifications/create-or-update-document-classifications"
export const GET_SINGLE_DOCUMENT_CLASSIFICATION_URL = "/organization/document-classifications/get-document-classification-detail"
export const GET_DOCUMENT_CLASSIFICATION_DROP_DOWN_URL = "/organization/v2/document-classifications/get-document-classification-grid-dropdown"
export const GET_DOCUMENT_CLASSIFICATION_ACTIVATE_DEACTIVATE_URL = "/organization/document-classifications/activate-or-deactivate-document-classification"

//REASON
export const GET_REASON_CLASSIFICAITON_URL = "/organization/file-incomplete-status/get-file-incomplete-status"
export const POST_CREATE_UPDATE_REASON_CLASSIFICAITON_URL = "/organization/file-incomplete-status/create-or-update-file-incomplete-status"
export const GET_SINGLE_REASON_CLASSIFICATION_URL = "/organization/file-incomplete-status/get-incomplete-status-detail"
export const GET_REASON_CLASSIFICATION_DROP_DOWN_URL = "/organization/v2/file-incomplete-status/get-incomplete-status-grid-dropdown"
export const GET_REASON_CLASSIFICATION_ACTIVATE_DEACTIVATE_URL = "/organization/file-incomplete-status/activate-or-deactivate-incomplete-status"

//OLD VERSION CLAIMS FILING
export const GET_CLAIMS_FILING_URL = "/organization/claim-filling-indicator-codes/get-claim-indicator-codes"
export const POST_CREATE_UPDATE_CLAIMS_FILING_URL = "/organization/claim-filling-indicator-codes/create-or-update-claim-indicator-codes"
export const GET_SINGLE_CLAIMS_FILING_URL = "/organization/claim-filling-indicator-codes/get-claim-indicator-codes-detail"

//NEW VERSION CLAIMS FILING
export const GET_NEW_CLAIMS_FILING_URL = "/organization/v2/claim-filling-indicator-codes/get-claim-indicator-codes"
export const POST_NEW_CREATE_UPDATE_CLAIMS_FILING_URL = "/organization/v2/claim-filling-indicator-codes/create-or-update-claim-indicator-codes"
export const GET_NEW_SINGLE_CLAIMS_FILING_URL = "organization/v2/claim-filling-indicator-codes/get-claim-indicator-codes-details"
export const GET_NEW_CLAIMS_FILING_DROPDOWN = "organization/v2/claim-filling-indicator-codes/get-code-groups"
export const DELETE_NEW_CLAIMS_FILING = "organization/v2/claim-filling-indicator-codes/activate-or-deactivate-claim-indicator-codes"


//EDI validate

export const EDI_VALIDATE_835_LIST_URL = "payment/get-single-edi-validation-output";


//NEW VERSION CLAIMS FILING
export const GET_ALL_EMAIL_TEMPLATE_URL = "/template/get-templates"
export const CREATE_UPDATE_EMAIL_TEMPLATE_URL = "/template/create-or-update-template"
export const GET_SINGLE_EMAIL_TEMPLATE_URL = "/template/get-template-details"
export const EMAIL_TEMPLATE_STATUS = "template/active-inactive-template"

//NEW VERSION CLAIMS FILING
export const GET_ALL_USER_WISE_DBK_URL = "/users/user-dbk/get-userdbk-list"
export const CREATE_UPDATE_USER_WISE_DBK_URL = "/users/user-dbk/create-or-update-userdbk"
export const GET_SINGLE_USER_WISE_DBK_URL = "/users/user-dbk/get-userdbk-detail"
export const ACTIVE_USER_WISE_DBK_URL = "/users/user-dbk/activate-or-deactivate-userdbk"
// SNIPPET
export const GET_SNIPPET_VIEW_URL = "/snipping/snipping-work-allocation"
export const SAVE_SNIPPET_URL = "/snipping/snipping-grid-save"
export const RE_ASSIGN_SNIPPET_URL = "/snipping/snipping-job-to-qa-review"

export const QUEUE_V2_REVERT_JOB = "/client/v2/job-monitor/revert-generated-job-status";




// Spring Lockbox
export const RETRIVE_ALL_SPRING_LOCKBOX_URL = "/rev_spring_converter/v1/retrive-spring-sftp-lockboxes";
export const GENERATE_SPRING_OUTPUT_FILE_URL = "/rev_spring_converter/v1/generate-spring-output-file";
export const EDI_VALIDATE_SPRING_LIST_URL = "/rev_spring_converter/v1/get-single-rev-spring-edi-validation-output";
export const POST_REJECT_SPRING_SFTP_FILE = "/rev_spring_converter/v1/reject-rev-spring-file";

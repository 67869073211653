import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import { refreshToken } from './Redux/auth/action';
import Swal from 'sweetalert2';

let headers = {

};

const token = localStorage.getItem("token")
const refresh_token = localStorage.getItem("refresh_token")


const domain = window.location.origin;


let API_URL = "http://localhost:3000"

if (domain == "https://xtract.aventusinformatics.com") {

    API_URL = process.env.REACT_APP_DEV_SERVER_API_URL + "/api";

}
else if (domain == "https://uat.db-xtract.com") {

    API_URL = process.env.REACT_APP_UAT_SERVER_API_URL + "/api";

}
else if (domain == "https://xtract-test.aventusinformatics.com") {

    API_URL = process.env.REACT_APP_TEST_SERVER_API_URL + "/api";

}
else if (domain == "https://training.db-xtract.com") {

    API_URL = process.env.REACT_APP_TRAINING_SERVER_API_URL + "/api";

}
else if (domain == "https://db-xtract.com") {

    API_URL = process.env.REACT_APP_PROD_SERVER_API_URL + "/api";

}
else if (domain == "https://test.db-xtract.com") {

    API_URL = process.env.REACT_APP_PROD_TEST_API_URL + "/api";

}
else if (domain == "https://demo.db-xtract.com") {

    API_URL = process.env.REACT_APP_DEMO_PROD_TEST_API_URL + "/api";

}
else if (domain == "http://localhost:3000") {

    API_URL = process.env.REACT_APP_DEV_SERVER_API_URL + "/api";

}
else {

    API_URL = process.env.REACT_APP_DEV_SERVER_API_URL + "/api";
    // API_URL = process.env.REACT_APP_UAT_SERVER_API_URL + "/api";

}

// API_URL = "http://192.168.0.147:8000" + "/api";
export const BACKEND_URL = API_URL;

const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers
});

axiosInstance.interceptors.request.use(
    async (config) => {

        const token = reactLocalStorage.get('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;

        }
        return config;

    },
    (error) => {

        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response);
        }),
    (error) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        if (error.response.status_code === 401) {
            const token = reactLocalStorage.remove('token');
            Swal.fire({
                icon: 'error',
                confirmButtonColor: '#1ba1a6',
                title: 'Session Timeout',
                customClass: {
                    title: 'swal-titleText'
                }
                // text: 'Session',
            }).then((result) => {


                // localStorage.clear();
                // window.location = "/";
                localStorage.clear();
                parent.open("about:blank", "_self");
                parent.close();

            })
        }
        else if (error.response.status === 401) {
            Swal.fire({
                icon: 'error',
                confirmButtonColor: '#1ba1a6',
                title: 'Session Timeout',
                customClass: {
                    title: 'swal-titleText'
                }
                // text: 'Session',
            }).then((result) => {


                localStorage.clear();
                // window.location = "/";
                // localStorage.clear();
                parent.open("about:blank", "_self");
                parent.close();

            })

        }
        else {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    },
);

export default axiosInstance;
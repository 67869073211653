import React, { useEffect, useRef } from 'react'
import DataTable from 'react-data-table-component';
import { createTheme } from 'react-data-table-component';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import useAutocomplete from '@mui/base/useAutocomplete';
import styled from 'styled-components/macro';
import { useState } from 'react';
import DetailDataTable from './ClientDetailDataTable';
import { useCallback } from 'react';
import { DateRangePicker, DatePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';
import { getDashboardReportData } from '../../../Redux/auth/action';
import { Tooltip } from "@mui/material";
import { getAllClientDropdown } from '../../../Redux/client/action';
import { getFacilityDropdown } from '../../../Redux/user/action';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import InputMask from "react-input-mask";
import DateMaskInput from './componenets/DateMaskInput';

const queueDatatableCustomStyles = {
    rows: {
        style: {
            minHeight: '20px', // override the row height
            fontSize: '1em',
            color: '#016b87',
            backgroundColor: '#D8E5E9',
            borderBottom: '1px solid white !important'

            // width: '100%'

        },
    },

    headCells: {
        style: {
            backgroundColor: '#F0F5F6',
            border: '1px solid #fff !important',
            borderBottom: '1px solid #fff !important',

            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            paddingTop: '10px',
            textTransform: "uppercase",
            fontSize: '0.9em',
            // fontWeight: '800',
            color: '#016b87 !important',
            opacity: 1,
            minHeight: "100px",

        },
        draggingStyle: {
            cursor: 'move',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
            fontColor: '#000000',
            fontWeight: "550",
            borderRight: '1px solid #fff !important',
        },
    },

};


function DashboardDataTable({
    loading,
    setLoading,
    setFacilities,
    setFullFacilities,
    setClients,
    setSelectIds,
    facilities,
    fullFacilities,
    clients,
    selectIds,
    filterClients, data, isNoData, copyData, setData, setIsNoData, setCopyData, customeDateTime, setCustomeDateTime, selectedFacility, setSelectedFacility, selectValue, setSelectValue,
    expandedRows,
    setExpandedRows,
    handleRowExpand,
    colorRow,
    detailExpandedRows,
    setDetailExpandedRows,
    clientTotal,
    filteredData,
    isfullscreen

}) {
    const [errorDate, setErrorDate] = useState(false)

    const fromDateRef = useRef(null)
    const fromTimeRef = useRef(null)
    const [isCopieValue, setIsCopieValue] = useState(false);

    const [fullClientExpand, setFullClientExpand] = useState(false);
    const [fullFacilityExpand, setFullFacilityExpand] = useState(false);


    const clientRef = useRef(null)
    const facilityRef = useRef(null)
    const dateTimeRef = useRef(null)

    const [isFocusedStatus, setIsFocusedStatus] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);
    // const [expandedRows, setExpandedRows] = useState([]);
    // const [detailExpandedRows, setDetailExpandedRows] = useState([]);


    // const [colorRow, setColorRow] = useState([]);
    const [dateTime, setDateTime] = useState(null);
    // const [data, setData] = useState({});
    // const [isNoData, setIsNoData] = useState(false);
    // const [copyData, setCopyData] = useState([]);



    const [selectedFacilityIds, setSelectedFacilityIds] = useState([])

    const [filterDateTime, setFilterDateTime] = useState(null);
    const [fromSinglrDate, setFromSinglrDate] = useState(null);
    const [toSingleDate, setToSingleDate] = useState(null);



    const handleKeyDown = (e, name) => {
        if (e.shiftKey === true && e.key === 'Tab') {
            e.preventDefault(); // Prevent the tab key from changing the selection
            if (name === 'facility') {
                clientRef.current.focus()
            }
        }
        else if (e.key === 'Tab') {
            e.preventDefault(); // Prevent the tab key from changing the selection
            if (name === 'client') {
                facilityRef.current.focus();
            } else {
                const dateRangePicker = dateTimeRef.current;
                if (dateRangePicker) {
                    const inputElement = dateRangePicker.querySelector('input');
                    if (inputElement) {
                        inputElement.focus();
                    }
                }
            }
        }
    }
    const ExpandedComponent = useCallback(
        ({ data }) => (
            <pre style={{ marginBottom: 'unset' }}>
                {expandedRows?.length > 0 && (
                    <DetailDataTable setIsCopieValue={setIsCopieValue} dataId={data.id} data={data?.facility} detailExpandedRows={detailExpandedRows} setDetailExpandedRows={setDetailExpandedRows}
                    />
                )}
            </pre>
        ),
        [expandedRows]
    );
    // const handleRowExpand = useCallback((row) => {

    //     const test = [...expandedRows]
    //     const isRowExpanded = expandedRows?.find((i) => i?.id === row?.id) ? true : false;
    //     const curentRaw = isRowExpanded ? [] : row

    //     // test.push(row)

    //     setExpandedRows(isRowExpanded ? test.filter(i => i.id != row.id) : [...expandedRows, row]);
    //     setColorRow(curentRaw);
    // });

    const handleFullClientExpand = () => {
        setFullClientExpand(!fullClientExpand)
    }
    const handleFullFacilityExpand = () => {
        setFullFacilityExpand(!fullClientExpand)
    }
    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }
    const columns = [
        {
            name: (
                <div style={{ width: '100%' }}>
                    <div className='mb-3 d-flex align-items-center justify-content-between'>
                        <p> Client</p>
                        {/* <span className='dashboard-detail-plus-icon' onClick={() => { handleFullClientExpand() }}>
                            {fullClientExpand ?

                                <i class="bi bi-dash"></i>
                                :
                                <i class="bi bi-plus"></i>
                            }
                        </span> */}
                    </div>
                    <div id='dashboard-client-select-container'>
                        <Select
                            ref={clientRef}
                            isMulti
                            options={clients}
                            value={selectValue}
                            className=""
                            classNamePrefix="select"
                            placeholder="Client"
                            menuPlacement="auto"
                            maxMenuHeight={300}
                            isClearable
                            name="client"
                            onChange={(val) => handleSelectChange(val)}
                            onKeyDown={(e) => { handleKeyDown(e, 'client') }}
                            onFocus={() => setIsFocusedStatus(true)}
                            onBlur={() => setIsFocusedStatus(false)}
                            styles={{
                                container: (styles, state) => ({
                                    ...styles,
                                    borderRadius: "9px",
                                }),
                                control: (styles, state) => ({
                                    ...styles,
                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                    borderStyle: "none", boxShadow: "none",
                                    borderRadius: "10px",
                                    maxHeight: state.isFocused ? "auto" : "30px",
                                }),
                                indicatorSeparator: styles => ({
                                    ...styles, width: "0px",
                                    minHeight: "20px"
                                }),

                                valueContainer: (styles, state) => ({
                                    ...styles,
                                    maxHeight: isFocusedStatus ? "auto" : "30px",
                                }),
                                menu: (styles) => ({
                                    ...styles,
                                    zIndex: 9999, // Add a custom z-index for the dropdown menu
                                  }),
                                  menuPortal: (styles) => ({
                                    ...styles,
                                    zIndex: 9999, // Add z-index for the menu portal as well if you're using it
                                  }),
                            }}
                           
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                    </div>

                </div>
            ),
            selector: row => row.name,
            cell: (row) => <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "350px" }} onClick={() => handleRowExpand(row)}>
                    <Tooltip disableInteractive title={`${row?.name}`} placement="top" arrow>
                        <span style={{ display: "block" }} className="text-truncate"> {row?.name} </span>
                    </Tooltip>
                </div>
                {!isNoData &&
                    <span className='dashboard-detail-plus-icon' onClick={() => { handleRowExpand(row) }}>
                        {expandedRows.find((i) => i?.id === row?.id) ?

                            <i class="bi bi-dash"></i>
                            :
                            <i class="bi bi-plus"></i>
                        }
                    </span>
                }

            </div>,
            conditionalCellStyles: [
                {
                    when: row => row.id == colorRow.id,
                    style: {
                        backgroundColor: '#D8E5E9',
                        color: '#016b87',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.id != colorRow.id,
                    style: {
                        backgroundColor: '#D8E5E9',
                        color: '#016b87',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
            sortable: false,
            width: '17%',
            colSpan: 10

        },
        {
            name: (
                <div style={{ width: '100%' }}>
                    <div className='mb-3 d-flex align-items-center justify-content-between'>
                        <p> Facility</p>
                        {/* <span className='dashboard-detail-plus-icon' onClick={() => { handleFullClientExpand() }}>
                            {fullClientExpand ?

                                <i class="bi bi-dash"></i>
                                :
                                <i class="bi bi-plus"></i>
                            }
                        </span> */}
                    </div>
                    <div>
                        <Select
                            ref={facilityRef}
                            options={facilities}
                            className=""
                            classNamePrefix="select"
                            placeholder="Facility"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            isClearable
                            isMulti
                            name="facility"
                            value={selectedFacility}
                            onChange={(val) => handleSelectChange(val, 'facility')}
                            onFocus={() => setIsFocusedFacility(true)}
                            onBlur={() => setIsFocusedFacility(false)}
                            onKeyDown={(e) => { handleKeyDown(e, 'facility') }}

                            styles={{
                                container: (styles, state) => ({
                                    ...styles,
                                    borderRadius: "9px",
                                }),
                                control: (styles, state) => ({
                                    ...styles,
                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",


                                    borderStyle: "none", boxShadow: "none",
                                    borderRadius: "10px",
                                    maxHeight: state.isFocused ? "auto" : "30px",
                                }),
                                indicatorSeparator: styles => ({
                                    ...styles, width: "0px",
                                    minHeight: "20px"
                                }),

                                valueContainer: (styles, state) => (
                                    {
                                        ...styles,
                                        maxHeight: isFocusedFacility ? "auto" : "30px",
                                    }
                                ),
                                menu: (styles) => ({
                                    ...styles,
                                    zIndex: 9999, // Add a custom z-index for the dropdown menu
                                  }),
                                  menuPortal: (styles) => ({
                                    ...styles,
                                    zIndex: 9999, // Add z-index for the menu portal as well if you're using it
                                  }),
                            }}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                    </div>

                </div>
            ),
            selector: row => "",
            width: '13%',

            sortable: false,
        },
        {
            name: (
                <div style={{ width: '100%' }}>
                    <div className='mb-3'>TAT (Date:Time)</div>

                    <DateMaskInput setCustomeDateTime={setCustomeDateTime} customeDateTime={customeDateTime} fromDateRef={fromDateRef} dateTimeRef={dateTimeRef} />
                </div>
            ),
            selector: row => row.tat_datetime,
            sortable: false,
            width: '11%'
        },
        {
            name:
                (
                    <div>CLOSE TO TAT</div>
                        
                ),
            selector: row => !isNoData ? clientTotal(row)?.close_to_tat : "",
            sortable: false,
            right: true,
            width: '7%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>Missed TAT</div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.missed_tat : "NO DATA",
            sortable: false,
            right: true,
            width: '6.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>File SPlit</div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.split_files : "",
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                     <div>Total Jobs</div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.total_jobs : "",
            sortable: false,
            right: true,
            width: '6%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'IMPORTED',
            selector: row => !isNoData ? clientTotal(row)?.my_work_pending_jobs : "",
            sortable: false,
            right: true,
            width: '5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>My Work</div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.my_work : "",
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                      <div>QA Review</div>                    
                ),
            selector: row => !isNoData ? clientTotal(row)?.qa_review_pending_jobs : "",
            sortable: false,
            right: true,
            width: '7%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'QA',
            selector: row => !isNoData ? clientTotal(row)?.qa_jobs : "",
            sortable: false,
            right: true,
            width: '4.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        // {
        //     name: 'Snipping',
        //     selector: row => !isNoData ? clientTotal(row)?.snipping_jobs : "",
        //     sortable: false,
        //     right: true,
        //     width: '5%',
        //     conditionalCellStyles: [
        //         {
        //             when: row => row,
        //             style: {
        //                 fontWeight: '900'
        //             },
        //         },


        //     ],
        // },
        // {
        //     name:
        //         (
        //             <div>
        //                 <div>Snipping</div>
        //                 <div>Inprocess</div>
        //             </div>
        //         ),

        //     selector: row => !isNoData ? clientTotal(row)?.snipping_pending_jobs : "",
        //     sortable: false,
        //     right: true,
        //     width: '6%',
        //     conditionalCellStyles: [
        //         {
        //             when: row => row,
        //             style: {
        //                 fontWeight: '900'
        //             },
        //         },


        //     ],
        // },

        {
            name: 'Pending',
            selector: row => !isNoData ? clientTotal(row)?.pending_jobs : "",
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'Completed',
            selector: row => !isNoData ? clientTotal(row)?.completed_jobs : "",
            sortable: false,
            right: true,
            width: '6.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
    ];

    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];

    const handleOpen = (e) => {
        // console.log(fromSinglrDate)
        // if (fromSinglrDate == null) {
        //     alert("$$")
        //     const button = document.querySelector('button.rs-calendar-header-title.rs-calendar-header-title-time.rs-btn.rs-btn-subtle.rs-btn-xs')
        //     button.textContent = '00:00'
        // }
    }

    const handleOnDateTimeChange = ({ name, value }) => {
        setExpandedRows([])
        let created_date = ""
        const nowDate = new Date()
        if (value !== null) {
            if (name === 'from') {
                setFromSinglrDate(value)
                filterClients(selectIds, selectedFacilityIds, value, null)

            } else if (name === 'to') {
                // if (
                //     (fromSinglrDate == null) &&
                //     (value.getHours() === nowDate.getHours() ||
                //         value.getMinutes() === nowDate.getMinutes())
                // ) {
                //     value = value.setHours(2, 3, 5, 9);

                // }
                setToSingleDate(value)
                filterClients(selectIds, selectedFacilityIds, fromSinglrDate, value)

            }
            // const datefrom = moment(value['0']).format('MM/DD/YYYY')
            // const dateto = moment(value['1']).format('MM/DD/YYYY')
            // setFilterDateTime(value)
            // filterClients(selectIds, selectedFacilityIds, value['0'], value['1'])
            // created_date = datefrom + " - " + dateto
        } else {
            setFromSinglrDate(null)
            setToSingleDate(null)
            filterClients(selectIds, selectedFacilityIds, null, null)

        }

        setDateTime(created_date)

    }
    const checkSelectedFecilityInitialClient = (selectedOptions) => {
        // Initialize an empty result array and a set to track unique labels
        var result = [...selectValue];
        var uniqueLabels = new Set();
        const clientId = selectValue?.map((value) => String(value?.value))

        // Loop through selected options
        for (var i = 0; i < selectedOptions.length; i++) {
            var selectedOption = selectedOptions[i];

            // Loop through the list of dictionaries to find matching options
            for (var j = 0; j < fullFacilities.length; j++) {
                var options = fullFacilities[j].options;
                for (var k = 0; k < options.length; k++) {
                    if (options[k].value === selectedOption.value && options[k].label === selectedOption.label) {
                        // Check if the label is unique before adding it to the result
                        if (!uniqueLabels.has(fullFacilities[j].label) && !clientId.includes(fullFacilities[j].id)) {
                            result.push({
                                "value": Number(fullFacilities[j].id),
                                "label": fullFacilities[j].label,
                            });
                            uniqueLabels.add(fullFacilities[j].label);
                        }
                        break;
                    }
                }
            }
        }
        ("Result:", result);
        return result
    }

    const handleSelectChange = (val, name = null) => {
        let selectedOptions = [];
        let selectedIds = [];

        if (val && val?.length > 0) {

            val?.map((option) => {
                selectedIds.push(option.value)
                selectedOptions.push(option)
            })
            // if (val?.length > 1) {
            //     setSelectedFacility([])
            //     setFacilities([])
            // }
        } else {
            // setFacilities([])
            setSelectedFacility([])
            setSelectedFacilityIds([])
        }

        if (name == "facility") {
            const initiaClientsOptions = checkSelectedFecilityInitialClient(selectedOptions)
            const clientIds = initiaClientsOptions?.map((value) => value?.value)
            setSelectedFacility(selectedOptions)
            setSelectedFacilityIds(selectedIds)
            setSelectValue(initiaClientsOptions)
            setFacilityOptions(clientIds)
            filterClients(clientIds, selectedIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)
        } else {
            // setClients(val.includes('all') ? ['all'] : selectedId)
            if (!(val && val?.length > 0)) {
                if (clients && clients.length > 0) {
                    const clientId = clients?.map((value) => value?.value)
                    setFacilityOptions(clientId)
                }
                filterClients([], [], customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)

            } else {
                getFacilityDropdown(selectedIds)((response) => {
                    if (response && response.status) {
                        const feciltyIdArr = []
                        setFacilities(response.data.facilities?.map(result => {
                            result?.facilities?.map(item => feciltyIdArr.push(item.value));
                            return { options: result?.facilities, label: result?.client }

                        }))

                        const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
                        setSelectedFacility(newArray)
                    }
                });
                filterClients(selectedIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)

            }

            setSelectValue(selectedOptions)
            setSelectIds(selectedIds)
            // setSelectedFacility([])
        }
    };

    const handleFacilityChange = (selected, setValues, values) => {
        setValues({ ...values, "facility": selected.map((option) => option?.value) });
        setSelectedFacility(selected)


    };
    const addClientsToSelect = (is_all = true) => {
        const clientData = []
        for (let i = 0; i < data?.length; i++) {
            let dict = {
                value: data[i].id,
                label: data[i].name
            }
            clientData.push(dict)
        }
        // console.log(clientData)
        // clientData.unshift({
        //     value: "all",
        //     label: "All"
        // })
        setClients(clientData)
    }

    const setFacilityOptions = (clientId) => {
        if (clientId.length > 0) {
            getFacilityDropdown(clientId)((response) => {
                if (response && response.status) {
                    const feciltyIdArr = []
                    setFacilities(response.data.facilities?.map(result => {
                        result?.facilities?.map(item => feciltyIdArr.push(item.value));
                        return { options: result?.facilities, label: result?.client, id: result?.pk }

                    }))

                }
            });
        }
    }

    // useEffect(() => {
    //     if (selectValue && selectValue.length > 0) {
    //         if (!selectValue.includes('all')) {
    //             const clientId = selectValue?.map((value) => value?.value)
    //             getFacilityDropdown(selectValue?.map((value) => value?.value))((response) => {
    //                 if (response && response.status) {
    //                     const feciltyIdArr = []
    //                     setFacilities(response.data.facilities?.map(result => {
    //                         console.log(result?.facilities, 'EEEEE')
    //                         result?.facilities?.map(item => feciltyIdArr.push(item.value));
    //                         return { options: result?.facilities, label: result?.client }

    //                     }))

    //                     const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
    //                     setSelectedFacility(newArray)
    //                 }
    //             });
    //             // setFacilityOptions(clientId)

    //         } else {
    //             setFacilities([])
    //         }
    //     }

    // }, [selectValue])


    const validateTime = (value) => {
        // Modify the value based on your requirements.
        let formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
        formattedValue = formattedValue.slice(0, 4); // Ensure it's not longer than 4 characters (HHMM format)
        formattedValue = formattedValue.replace(/(\d{2})(\d{2})/, "$1:$2"); // Add the colon

        return formattedValue
    }

    const validateDate = (value, cursorPosition) => {
        // Remove non-numeric characters
        let numericValue = value.replace(/\D/g, "");

        // Ensure it's not longer than 8 characters (MM/DD/YYYY format)
        numericValue = numericValue.slice(0, 8);

        // Add the slashes when the second digit is entered for month and day
        if (numericValue.length >= 2 && numericValue.charAt(2) !== '/') {
            numericValue = numericValue.slice(0, 2) + "/" + numericValue.slice(2);
        }
        if (numericValue.length >= 5 && numericValue.charAt(5) !== '/') {
            numericValue = numericValue.slice(0, 5) + "/" + numericValue.slice(5);
        }

        // Adjust formatting if cursor is before a slash
        if (cursorPosition <= 2 && numericValue.charAt(2) === '/') {
            numericValue = numericValue.slice(0, 2) + numericValue.slice(3);
        }
        if (cursorPosition <= 5 && numericValue.charAt(5) === '/') {
            numericValue = numericValue.slice(0, 5) + numericValue.slice(6);
        }

        return numericValue;
    }




    const handleCustomTimeChange = (e, name) => {
        const { value, selectionStart } = e.target;
        // Ensure that the cursor position is maintained.
        const caretPosition = selectionStart;

        // Modify the value based on your requirements.
        let formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
        formattedValue = formattedValue.slice(0, 4); // Ensure it's not longer than 4 characters (HHMM format)
        formattedValue = formattedValue.replace(/(\d{2})(\d{2})/, "$1:$2"); // Add the colon

        setCustomeDateTime((prevState) => {
            return {
                ...prevState,
                [name]: formattedValue,
            };
        });
        if (name === 'fromTime') {
            if (formattedValue) {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, formattedValue, customeDateTime.toTime)

            } else {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, null, customeDateTime.toTime)

            }
        } else if (name === 'toTime') {
            if (formattedValue) {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.toDate, formattedValue)

            }
            else {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.toDate, null)

            }
        }

        // Restore cursor position
        const input = e.target;
        input.selectionStart = caretPosition;
        input.selectionEnd = caretPosition;
    };

    const handleCustomDateTimeChange = (e, name) => {
        const value = e.target.value

        if (value != '__/__/____' && value != '' && value != '__:__') {
            if (name === 'fromDate') {
                if (!isNaN(new Date(value))) {


                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            // [name]: new Date(value),
                            [name]: value,
                            initialfromDate: value,
                            fromDateInValid: false

                        };
                    });

                    // filterClients(selectIds, selectedFacilityIds, new Date(value), customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)
                } else {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            fromDateInValid: true

                        };
                    });
                }

            } else if (name === 'fromTime' && value != '__:__' && value != '') {
                const numberValue = value.replace(/:/g, '')
                if (numberValue.length == 4 && !isNaN(numberValue)) {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: validateTime(value),

                        };
                    });
                    // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, value, customeDateTime.toTime)

                }
                else if (numberValue.length <= 4 && !isNaN(numberValue)) {
                    // const replacedString = value && value?.replace(/\D/g, '')

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: value,

                        };
                    });

                }


            }
            else if (name === 'toDate') {
                if (!isNaN(new Date(value))) {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            // [name]: new Date(value),
                            [name]: value,
                            initialtoDate: value,
                            toDateInValid: false
                        };
                    });
                    // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, new Date(value), customeDateTime.fromTime, customeDateTime.toTime)

                } else {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            toDateInValid: true

                        };
                    });
                }
            }
            else if (name === 'toTime' && value != '__:__' && value != '') {
                const numberValue = value.replace(/:/g, '')
                if (numberValue.length == 4 && !isNaN(numberValue)) {


                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: validateTime(value),

                        };
                    });
                    // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, value)
                } else if (numberValue.length <= 4 && !isNaN(numberValue)) {
                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: value,

                        };
                    });
                }

            }
        }
        else {

            if (name === 'fromDate') {


                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: null,
                        initialfromDate: ""

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, null, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)

            }
            else if (name === 'fromTime') {
                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: '',

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, null, customeDateTime.toTime)
            }
            else if (name === 'toDate') {


                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: null,
                        initialtoDate: ""

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, null, customeDateTime.fromTime, customeDateTime.toTime)

            }
            else if (name === 'toTime') {
                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: '',

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, null)

            }

        }

    }
    const handleDateInputChange = (e, name) => {
        const value = e.target.value
        const cursorPosition = e.target.selectionStart; // Get the cursor position

        const splitedValue = value.split(" ")
        if (name === 'fromDate') {
            let fromTime = customeDateTime.fromTime
            if (splitedValue[1] != undefined && splitedValue[1] != '' && splitedValue[1].length <= 4) {
                fromTime = splitedValue[1]
            } else if (splitedValue[1] != undefined && splitedValue[1].length > 4) {
                fromTime = splitedValue[1].split(':').slice(0, 2).join(':'); // Extract only the first two parts (HH:MM)

            }

            setCustomeDateTime((prevState) => {
                return {
                    ...prevState,
                    [name]: validateDate(value, cursorPosition),
                    fromTime,

                };
            });
        }
        else if (name === 'toDate') {
            let toTime = customeDateTime.toTime
            if (splitedValue[1] != undefined && splitedValue[1] != '') {
                toTime = splitedValue[1]
            }
            setCustomeDateTime((prevState) => {
                return {
                    ...prevState,
                    [name]: validateDate(value, cursorPosition),
                    toTime,


                };
            });
        }
    }
    return (
        <DataTable
            progressPending={loading}
            columns={columns}
            data={filteredData}
            customStyles={queueDatatableCustomStyles}

            expandableRows
            expandOnRowClicked={false}
            onRowExpandToggled={handleRowExpand}
            expandableRowsComponent={ExpandedComponent}
            expandableRowsHideExpander
            expandableRowExpanded={(row) => expandedRows.find((i) => i?.id === row?.id) ? true : false}
        // theme="solarized"

        />
    )
}

export default DashboardDataTable



import {  CHANGE_PASSWORD_URL, FORGOT_PASSWORD_URL } from "../../api";
import axiosInstance from "../../axiosInstance";


export const setResetPassord = (props) => onResponse => {
    console.log(props);
    try {
        axiosInstance.post(FORGOT_PASSWORD_URL , props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const setChangePassord = (props) => onResponse => {
    console.log(props);
    try {
        axiosInstance.post(CHANGE_PASSWORD_URL , props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};



import React, { useCallback, useMemo } from 'react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../Layout/admin/Includes/Footer';
import Master from '../../../Layout/admin/Master/index';
import { getDashboardLoggedStaffList, getUserDashboardDailyData } from '../../../Redux/dashboard/action';
import powerbtn from '../../../assets/img/power-button.svg';
import DataTable from 'react-data-table-component';
import { GET_USER_DASHBOARD_DAILY_DATA } from '../../../api';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import styled from 'styled-components/macro';
import Flatpickr from "react-flatpickr";
import Select from 'react-select'
import "flatpickr/dist/themes/material_blue.css";
import { today_date } from './functions';
import { GrUserAdmin } from 'react-icons/gr';
import { FiUsers } from 'react-icons/fi';
import { RiUserSettingsLine } from 'react-icons/ri';
import PieChart from "./componenets/PieChart"
import { getDashboardGridData, getDashboardReportData, getDashboardUserData } from '../../../Redux/auth/action';
import TimeLine from './componenets/TimeLine';
import DashboardDataTable from './DashboardDataTable';
import UserDashboardCard from './UserDashboardCard';
import AdminDashboardCard from './AdminDashboardCard';
import 'rsuite/dist/rsuite.min.css';
import Logo from "../../../assets/img/Xtract.png";



import bottile from "../../../assets/img/wish.png";
import peoples from "../../../assets/img/peoples.svg";
import img__avatar from "../../../assets/img/img_avatar.png";
import { toCamelCase } from "./functions"
import OwlCarousel from 'react-owl-carousel';
import { Tooltip } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import animationData from '../../../assets/animations/birthday1.json'; // Replace with the path to your animation.json file
import animationData1 from '../../../assets/animations/birthday2.json'; // Replace with the path to your animation.json file
import Lottie from 'lottie-react';
import Confetti from 'react-confetti'
import DashBoardSkeleton from './componenets/DashBoardSkeleton';
import moment from 'moment';
import { getFacilityDropdown } from '../../../Redux/user/action';
import { Modal } from 'react-bootstrap';


const Dashboard = () => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [colorRow, setColorRow] = useState([]);
    const [detailExpandedRows, setDetailExpandedRows] = useState([]);
    const [data, setData] = useState({});
    const [isNoData, setIsNoData] = useState(false);
    const [copyData, setCopyData] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [facilities, setFacilities] = useState([])
    const [fullFacilities, setFullFacilities] = useState([])
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectIds, setSelectIds] = useState()
    const [customeDateTime, setCustomeDateTime] = useState({
        toDateInValid: false,
        fromDateInValid: false,
        initialfromDate: null,
        initialtoDate: null,
        fromDate: null,
        fromTime: "",
        toDate: null,
        toTime: "",
    })


    const [isfullscreen, setIsfullscreen] = useState(false)
    const [isBirthdaySeen, setIsBirthdaySeen] = useState(localStorage.getItem('is_birthday_seen') == 'true' ? true : false);
    const [isBirthdayToday, setIsBirthdayToday] = useState(false);
    const [isConfilty, setConfilty] = useState(false);
    const [dashboardData, setDashboardData] = useState({});
    const [dashboardDataLoader, setDashboardDataLoader] = useState(false);
    const [shiftOption, setShiftOption] = useState([{ value: 'morning', label: 'MORNING' }]);
    const [update, setUpdate] = useState(false)
    const user = JSON.parse(localStorage.getItem("user"));

    const handleChangeShift = (e) => {
        localStorage.setItem('shift', JSON.stringify(e));
        setShiftOption(e)
        setUpdate(!update)
    }
    const loadDashboardData = () => {
        const shift = localStorage.getItem('shift', '') ? JSON.parse(localStorage.getItem('shift', '')) : '';
        setDashboardDataLoader(true)
        getDashboardGridData({ type: shift?.value ? shift?.value : 'morning' })((response) => {
            if (response?.status && response.status_code == 200) {
                setIsBirthdayToday(response?.data?.date_of_birth?.some(data => data.username === user?.username))
                setConfilty(response?.data?.date_of_birth?.some(data => data.username === user?.username))
                setDashboardDataLoader(false)
                setDashboardData(response?.data)
            }
            else {

            }
        })
        if (shift) {
            setShiftOption(shift)
        }
        setTimeout(() => {
            if (!localStorage.getItem('permission_reload')) {
                // parent.location.reload();
                localStorage.setItem('permission_reload', true)
            }
        }, 200);

    }
    const loadDataTableData = (initialLoad = false) => { //api call dashboard
        // ==============
        setLoading(true)
        getDashboardReportData()((response) => {
            if (response?.status && response.status_code == 200) {

                const data = response?.data


                if (initialLoad) { setData(data); setCopyData(data) } else { filterClients(selectValue?.map(i => i.value), selectedFacility?.map(i => i.value), customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime, data) }

                setIsNoData(false)
                const clientData = []
                const clientId = []
                for (let i = 0; i < data?.length; i++) {
                    let dict = {
                        value: data[i].id,
                        label: data[i].name
                    }
                    clientData.push(dict)
                    clientId.push(data[i].id)

                }
                getFacilityDropdown(clientId)((response) => {
                    if (response && response.status) {
                        const feciltyIdArr = []
                        const FullFacilities = []
                        setFacilities(response.data.facilities?.map(result => {
                            result?.facilities?.map(item => feciltyIdArr.push(item.value));
                            FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
                            return { options: result?.facilities, label: result?.client, id: result?.pk }

                        }))

                        setFullFacilities(FullFacilities)
                        const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
                        setSelectedFacility(newArray)
                    }
                });
                setClients(clientData)
                setLoading(false)
            }
            else {
                setIsNoData(true)

            }
        })
    }
    useEffect(() => {
        loadDashboardData()
    }, [update]);

    useEffect(() => {
        if (isBirthdayToday) {
            setTimeout(() => {
                setIsBirthdayToday(false)
                localStorage.setItem('is_birthday_seen', true)
            }, 4000);
        }
    }, [isBirthdayToday]);


    const currentDate = new Date();

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = currentDate.getDay();

    // Get the current time in hours (12-hour format)
    let currentHours = currentDate.getHours();
    // Determine whether it's morning, evening, or night
    let timeOfDay1;
    if (currentHours >= 5 && currentHours < 12) {
        timeOfDay1 = "Morning";
    } else if (currentHours >= 12 && currentHours < 17) {
        timeOfDay1 = "Afternoon";
    } else if (currentHours >= 17 && currentHours < 21) {
        timeOfDay1 = "Evening";
    } else {
        timeOfDay1 = "Day";

    }

    const timeOfDay = currentHours >= 12 ? "PM" : "AM";

    if (currentHours > 12) {
        currentHours -= 12;
    } else if (currentHours === 0) {
        currentHours = 12; // 0 should be displayed as 12 AM
    }

    // Get the current time in minutes
    const currentMinutes = currentDate.getMinutes();


    // Format the date string
    const formattedDate = `${getDayName(dayOfWeek)}, ${padZero(currentHours)}:${padZero(currentMinutes)} ${timeOfDay}`;


    // Function to get the day name from the day index
    function getDayName(dayIndex) {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return daysOfWeek[dayIndex];
    }

    // Function to pad single digits with a leading zero
    function padZero(number) {
        return number < 10 ? `0${number}` : number;
    }

    const checkUserPermission = (check) => {
        let status = false
        if (user?.is_superuser) {
            status = true;
        }
        else {
            if (localStorage.getItem('permissions') != 'undefined' && localStorage.getItem('permissions')) {
                var permissions = JSON.parse(localStorage.getItem('permissions'))
                status = permissions.includes(check)
            }
        }
        return status
    }
    function isEmptyDataAllConditions(fromDate, toDate, fromTime, toTime) {
        let isEmpty = false
        if (fromDate && fromDate !== '__/__/____' && !moment(fromDate, "MM/DD/YYYY", true).isValid()) {
            isEmpty = true;
        }
        if (toDate && toDate !== '__/__/____' && !moment(toDate, "MM/DD/YYYY", true).isValid()) {
            isEmpty = true;
        }

        // Add time validation here
        if (fromTime && fromTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(fromTime)) {
            isEmpty = true;
        }

        if (toTime && toTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(toTime)) {
            isEmpty = true;
        }
        if (toDate == '' && fromDate == '' && toTime && fromTime && toTime !== '__:__' && fromTime !== '__:__' && !(!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(toTime)) && !(!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(fromTime))) {
            if (fromTime > toTime) {
                isEmpty = true;

            }
        }
        // Compare fromDate and toDate
        if (moment(fromDate, "MM/DD/YYYY").isAfter(moment(toDate, "MM/DD/YYYY"))) {
            isEmpty = true;
        }
        return isEmpty;
    }

    function filterClients(clientId = [], facilityId = [], fromDate = null, toDate = null, fromTime = null, toTime = null, actualData = null) {
        fromTime = fromTime === '' || fromTime === '__:__' ? null : fromTime
        toTime = toTime === '' || toTime === '__:__' ? null : toTime

        if (fromDate != '' && fromDate != null && fromDate != '__/__/____') {
            fromDate = new Date(fromDate)
        }
        else {
            fromDate = null
        }
        if (toDate != '' && toDate != null && toDate != '__/__/____') {
            toDate = new Date(toDate)

        }
        else {
            toDate = null
        }


        let filteredData = [...copyData]; // Create a copy of the original data
        if (actualData?.length > 0) {
            filteredData = [...actualData]
            setCopyData(filteredData)
        }
        if (clientId.length > 0 && !clientId.includes('all')) {
            filteredData = filteredData.filter(client => clientId.includes(client.id));
        }

        if (facilityId.length > 0) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.filter(facility => facilityId.includes(facility.id));
                return { ...client, facility: filteredFacilities };
            });
            filteredData = filteredData.filter(client => client.facility.length > 0);

        }
        if (isEmptyDataAllConditions(fromDate, toDate, fromTime, toTime)) {
            filteredData = []
        }
        else if (fromDate != null && toDate != null && fromTime != null && toTime != null) {

            const fromTimeArr = fromTime.split(':')
            const toTimeArr = toTime.split(':')
            fromDate.setHours(fromTimeArr[0]);
            fromDate.setMinutes(fromTimeArr[1]);
            toDate.setHours(toTimeArr[0]);
            toDate.setMinutes(toTimeArr[1]);
            fromDate.setSeconds(0);
            toDate.setSeconds(0);


            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat);

                        return tatDate.getTime() >= fromDate.getTime() && tatDate.getTime() <= toDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null && fromTime != null && toDate != null) {

            const fromTimeArr = fromTime.split(':')
            fromDate.setHours(fromTimeArr[0]);
            fromDate.setMinutes(fromTimeArr[1]);
            fromDate.setSeconds(0);
            toDate.setSeconds(0);


            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat);

                        return tatDate.getTime() >= fromDate.getTime() && tatDate.getTime() <= toDate.getTime() || tatDate.getTime() == fromDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }


        else if (fromDate != null && toDate != null && toTime != null) {
            const toTimeArr = toTime.split(':')


            toDate.setHours(toTimeArr[0]);
            toDate.setMinutes(toTimeArr[1]);
            fromDate.setSeconds(0);
            toDate.setSeconds(0);


            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat);

                        return tatDate.getTime() >= fromDate.getTime() && tatDate.getTime() <= toDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null && fromTime != null) {
            const fromTimeArr = fromTime.split(':')
            fromDate.setHours(fromTimeArr[0]);
            fromDate.setMinutes(fromTimeArr[1]);
            fromDate.setSeconds(0);
            fromDate = fromDate.getTime();
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        let tatDate = new Date(tat.internal_tat);
                        tatDate = tatDate.getTime();
                        return tatDate == fromDate;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (toDate != null && toTime != null) {
            // alert("toDate !== null && fromTime !== null")           
            const toTimeArr = toTime.split(':')
            toDate.setHours(toTimeArr[0]);
            toDate.setMinutes(toTimeArr[1]);
            // toDate.setSeconds(0);
            toDate = toDate.getTime();
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        let tatDate = new Date(tat.internal_tat)
                        // tatDate = tatDate.setSeconds(0);
                        tatDate = tatDate.getTime();
                        return tatDate <= toDate;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromTime != null && toTime != null) {

            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        // const tatTime = tatDate.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

                        // return tatTime >= fromDate && tatTime <= toDate
                        const tatTime = tat.internal_tat.split(" ")[1]; // Extract time part from internal_tat
                        return tatTime >= fromTime && tatTime <= toTime;

                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null && toDate != null) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat).setHours(0, 0, 0, 0)
                        fromDate.setHours(0, 0, 0, 0)
                        toDate.setHours(0, 0, 0, 0)
                        return tatDate >= fromDate.getTime() && tatDate <= toDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null) {

            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        tatDate.setHours(0, 0, 0, 0)
                        fromDate.setHours(0, 0, 0, 0)
                        return tatDate.getTime() == fromDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (toDate != null) {
            toDate = toDate.setHours(0, 0, 0, 0)
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat).setHours(0, 0, 0, 0);
                        return tatDate <= toDate;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromTime != null) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        const tatTime = tatDate.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

                        return tatTime == fromTime;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (toTime != null) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        const tatTime = tatDate.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

                        return tatTime <= toTime;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }



        if (filteredData.length > 0) {
            setData(filteredData);
            setIsNoData(false)
        } else {

            setIsNoData(true)

            const emptyDict = [
                {
                    "name": "",
                    "id": '',
                    "facility": [
                        {
                            "name": "",
                            "id": '',
                            "tat": [
                                {
                                    "internal_tat": "",
                                    "close_to_tat": '',
                                    "missed_tat": 0,
                                    "total_jobs": 0,
                                    "completed_jobs": 0,
                                    "pending_jobs": 0,
                                    "my_work": 0,
                                    "qa_jobs": 0,
                                    "split_files": 0,
                                    "qa_review_pending_jobs": 0,
                                    "my_work_pending_jobs": 0
                                }
                            ]
                        }
                  
                    ]
                }
            ]
            setData(emptyDict);
            filteredData = emptyDict
        }
        return filteredData;
    }
    useEffect(() => {
        const is_refresh = checkUserPermission("auto_refresh")
        const refresh_time = user?.settings?.find((i) => i?.key_name === "auto_refresh_time")?.value
        if (is_refresh && refresh_time && isfullscreen) {
            const millySecond = refresh_time * 1000
            let timer = setInterval(() => {

                const tabs_json = parent.localStorage.getItem("bottomTab")
                const tabs = JSON.parse(tabs_json);
                const urlParams = new URLSearchParams(window.location.search);
                const iframeId = urlParams.get('iframeId');
                if (tabs?.currentTab?.id == iframeId) {
                    loadDataTableData()
                    loadDashboardData()
                    // filterClients(selectValue?.map(i => i.value), selectedFacility?.map(i => i.value), customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)
                }

            }, millySecond)
            return () => {
                clearInterval(timer)
            }
        }
    }, [selectValue, selectedFacility, customeDateTime, isfullscreen])

    useEffect(() => {
        filterClients(selectValue?.map(i => i.value), selectedFacility?.map(i => i.value), customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime, null)
    }, [customeDateTime])
    useEffect(() => {
        loadDataTableData(true)
    }, []);

    const handleRowExpand = useCallback((row) => {
        const test = [...expandedRows]
        const isRowExpanded = expandedRows?.find((i) => i?.id === row?.id) ? true : false;
        const curentRaw = isRowExpanded ? [] : row
        setExpandedRows(isRowExpanded ? test.filter(i => i.id != row.id) : [...expandedRows, row]);
        setColorRow(curentRaw);

    });
    const handledashboardTablemodalClose = () => {
        setIsfullscreen(false)
        parent.document.getElementById('user-dashboard-datatable-modal').click()
    }
    const handledashboardTablemodalOpen = () => {
        setIsfullscreen(true)
        if (!isfullscreen) {
            const modalElement = parent.document.getElementById('user-dashboard-datatable-modal');
            modalElement?.click();
        }
    }
    // ==================Total=====================
    const clientTotal = useCallback((data) => {
        const totals = {
            close_to_tat: 0,
            completed_jobs: 0,
            missed_tat: 0,
            my_work: 0,
            pending_jobs: 0,
            qa_jobs: 0,
            split_files: 0,
            total_jobs: 0,
            my_work_pending_jobs: 0,
            qa_review_pending_jobs: 0,
            snipping_jobs: 0,
            snipping_pending_jobs: 0
        };
        data?.facility?.forEach(facility => {
            facility?.tat?.forEach(tat => {
                totals.close_to_tat += tat.close_to_tat;
                totals.completed_jobs += tat.completed_jobs;
                totals.missed_tat += tat.missed_tat;
                totals.my_work += tat.my_work;
                totals.pending_jobs += tat.pending_jobs;
                totals.qa_jobs += tat.qa_jobs;
                totals.split_files += tat.split_files;
                totals.total_jobs += tat.total_jobs;
                totals.my_work_pending_jobs += tat.my_work_pending_jobs;
                totals.qa_review_pending_jobs += tat.qa_review_pending_jobs;
                totals.snipping_jobs += tat.snipping_jobs;
                totals.snipping_pending_jobs += tat.snipping_pending_jobs;
            });
        });
        return totals
    },[data])
    // ====================remove the total ZERO Row ============================
    const getFullTotalCard = copyData.reduce((acc, entry) => {

        // Increment the facility count by the number of facilities in each entry
        acc.facility += entry.facility?.length || 0;
    
        entry.facility.forEach(facility => {
            facility.tat.forEach(tat => {
                acc.close_to_tat += tat?.close_to_tat || 0;
                acc.completed_jobs += tat?.completed_jobs || 0;
                acc.missed_tat += tat?.missed_tat || 0;
                acc.split_files += tat?.split_files || 0;
                acc.total_jobs += tat?.total_jobs || 0;
            });
        });
        return acc;
    }, {
        close_to_tat: 0,
        completed_jobs: 0,
        missed_tat: 0,
        split_files: 0,
        total_jobs: 0,
        client: copyData?.length || 0, // Total clients (number of entries)
        facility: 0, // Initialize facility count
    });
        
    const filterFacilitiesWithZeroTotals = (data) => {
        let close_to_tat = 0
        let completed_jobs = 0
        let missed_tat = 0
        let split_files = 0
        let total_jobs = 0
        let facility = 0
        let client = 0

        const result =  data?.map(item => {
            // Filter facilities
            const filteredFacilities = item?.facility?.map(facility => {
                // Check if all TAT entries are zero
                // Check for non-zero TAT entries and accumulate totals
            const hasNonZeroTat = facility?.tat?.filter(tat => {
                // Accumulate totals from each TAT entry
                close_to_tat += tat?.close_to_tat || 0;
                completed_jobs += tat?.completed_jobs || 0;
                missed_tat += tat?.missed_tat || 0;
                split_files += tat?.split_files || 0;
                total_jobs += tat?.total_jobs || 0;

                // Return true if any TAT value is non-zero
                return !(
                    tat?.close_to_tat === 0 &&
                    tat?.completed_jobs === 0 &&
                    tat?.missed_tat === 0 &&
                    tat?.my_work === 0 &&
                    tat?.pending_jobs === 0 &&
                    tat?.qa_jobs === 0 &&
                    tat?.split_files === 0 &&
                    tat?.total_jobs === 0 &&
                    tat?.my_work_pending_jobs === 0 &&
                    tat?.qa_review_pending_jobs === 0 &&
                    tat?.snipping_jobs === 0 &&
                    tat?.snipping_pending_jobs === 0
                );
            });
                // Include the facility if at least one TAT entry is non-zero
                return {
                    ...facility,
                    tat:hasNonZeroTat
                };
            })?.filter((facData)=>facData?.tat?.length>0);
            // Return the item with the filtered facilities
            facility+=filteredFacilities?.length
            return { ...item, facility: filteredFacilities };
        }).filter(item => item?.facility?.length > 0); // Remove items with no valid facilities
        return {data:result, close_to_tat,
            completed_jobs,
            missed_tat,
            split_files,
            total_jobs,
            facility,
            client:result?.length}
    };
    const finalFilterData = dashboardData?.is_superuser_dashboard?filterFacilitiesWithZeroTotals(data):[]
    return (
        <React.Fragment>
            <Master isfullscreen={isfullscreen} setIsfullscreen={setIsfullscreen} />
            <main id="main" className="main">

                {/* <div className="pagetitle tab-fixed-header">
                    <h1>Dashboard</h1>
                    
                </div> */}

                <h1 id='page-title' className='d-none' >Dashboard</h1>
                {isConfilty &&
                    <Confetti
                        height={900}
                        numberOfPieces={50}
                    />
                }
                <div style={dashboardData?.date_of_birth?.length > 0 ? { display: "grid" } : { display: 'block' }} className='twocard-grid mb-4'>
                    <div className='p-3  card  left-birthcard-card-contaner'>
                        <span>{formattedDate}</span>
                        <h4 className='mt-1 mb-1'>Fantastic {timeOfDay1}, {user?.name}!</h4>
                        <span>{dashboardData?.daily_quotes}</span>
                    </div>
                    {isBirthdayToday && !isBirthdaySeen ?
                        <div style={dashboardData?.date_of_birth?.length > 0 ? {
                            display: "grid",
                            // alignContent: 'center',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            // justifyItems: 'center',
                        } : { display: 'none' }} className='p-3 card right-birthcard-card-contaner current-birthday-card'>
                            <div className='ballon-lottie-container'>
                                <Lottie animationData={animationData} width={900} height={300} />
                            </div>
                            <Confetti
                                height={200}
                                numberOfPieces={50}
                            />
                            {dashboardData?.date_of_birth && dashboardData?.date_of_birth?.length > 0 &&
                                <div className='scroll-birth-conatiner d-flex'>
                                    <div className='right-profile-card-contaner scroll-content'>

                                        <div className='left-birthcard-card-contaner'>
                                            <h4 className='mt-1 mb-1'>Happy Birthday To You...</h4>
                                            <span>{user?.username}</span>
                                            <p className='mt-2'>Wishing you a year filled with joy, love, endless possibilities. Enjoy your special day to the fullest!</p>
                                        </div>
                                        <div className='people-img-container'>
                                            <div className='left-bottile-continer'>

                                                <Lottie animationData={animationData1} width={300} height={300} />

                                            </div>
                                            <img alt='left-img' src={peoples}></img>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div style={dashboardData?.date_of_birth?.length > 0 ? {
                            display: "grid",
                            // alignContent: 'center',
                            // justifyContent: 'center',
                            alignItems: 'center',
                            justifyItems: 'center',
                        } : { display: 'none' }} className='p-3 card right-birthcard-card-contaner'>
                            <div className='left-bottile-continer'>
                                <div className='left-bottile-image'>
                                    <img alt='left-img' src={bottile}></img>
                                </div>
                                <p className='mt-2' >Wishes</p>
                            </div>
                            {dashboardData?.date_of_birth && dashboardData?.date_of_birth?.length > 0 &&
                                <div className='scroll-birth-conatiner d-flex'>
                                    <div className='right-profile-card-contaner scroll-content'>
                                        <OwlCarousel
                                            items={4}
                                            className="owl-theme"
                                            nav={false}
                                            dots={false}
                                            autoplay={true}
                                            autoplayTimeout={5000}
                                            margin={4}
                                        >
                                            {dashboardData?.date_of_birth?.map((item, index) =>
                                                <div className='signle-card-container'>
                                                    <div className='left-img-avatar'>
                                                        <img src={img__avatar} />
                                                    </div>
                                                    <div className='right-profile'>
                                                        <Tooltip disableInteractive title={item?.name} placement='top' arrow>
                                                            <p className='main text-truncate' style={{ width: "150px" }}>{item.name}</p>
                                                        </Tooltip>
                                                        <p>It's birthday</p>

                                                    </div>
                                                </div>
                                            )}
                                        </OwlCarousel>

                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {!dashboardDataLoader ?
                    <div className='row mb-5'>

                        {dashboardData?.is_superuser_dashboard ?
                            <AdminDashboardCard cardTotal={getFullTotalCard} dashboardData={dashboardData} dashboardDataLoader={dashboardDataLoader} />
                            :
                            <UserDashboardCard shiftOption={shiftOption} handleChangeShift={handleChangeShift} dashboardData={dashboardData} />
                        }
                        {dashboardData?.is_superuser_dashboard && !isfullscreen&&
                            <div style={{ borderRadius: '10px' }} className='dashboard-main-table-container mt-2'>
                                {/* <h4 className='mb-2'>Report Table:</h4> */}
                                <div className='d-flex justify-content-end pe-2 pb-1'>
                                    <Tooltip disableInteractive title="Full Screen" placement='top' arrow>
                                        <div>
                                            <i onClick={handledashboardTablemodalOpen} class="bi bi-arrows-fullscreen "></i>
                                        </div>
                                    </Tooltip>
                                </div>



                                <DashboardDataTable
                                    isfullscreen={isfullscreen}
                                    clientTotal={clientTotal}
                                    selectedFacility={selectedFacility}
                                    setSelectedFacility={setSelectedFacility}
                                    selectValue={selectValue}
                                    setSelectValue={setSelectValue}
                                    customeDateTime={customeDateTime}
                                    setCustomeDateTime={setCustomeDateTime}
                                    data={data}
                                    filteredData={finalFilterData?.data}
                                    setData={setData}
                                    isNoData={isNoData}
                                    setIsNoData={setIsNoData}
                                    copyData={copyData}
                                    setCopyData={setCopyData}
                                    filterClients={filterClients}
                                    setFacilities={setFacilities}
                                    setFullFacilities={setFullFacilities}
                                    setClients={setClients}
                                    setSelectIds={setSelectIds}
                                    facilities={facilities}
                                    fullFacilities={fullFacilities}
                                    clients={clients}
                                    selectIds={selectIds}
                                    loading={loading}
                                    setLoading={setLoading}
                                    expandedRows={expandedRows}
                                    setExpandedRows={setExpandedRows}
                                    handleRowExpand={handleRowExpand}
                                    colorRow={colorRow}
                                    detailExpandedRows={detailExpandedRows}
                                    setDetailExpandedRows={setDetailExpandedRows}

                                />
                            </div>
                        }
                    </div> :
                    <DashBoardSkeleton />
                }

                <Modal show={isfullscreen} fullscreen={true} keyboard={true} onHide={handledashboardTablemodalClose}>
                    <Modal.Header closeButton>
                        <div className='logo d-flex align-items-center'>
                            <img src={Logo} alt="Xtract" />
                        </div>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='p-3 dashboard-main-table-container'>
                        <DashboardDataTable
                                    isfullscreen={isfullscreen}
                                    clientTotal={clientTotal}
                                    selectedFacility={selectedFacility}
                                    setSelectedFacility={setSelectedFacility}
                                    selectValue={selectValue}
                                    setSelectValue={setSelectValue}
                                    customeDateTime={customeDateTime}
                                    setCustomeDateTime={setCustomeDateTime}
                                    data={data}
                                    filteredData={finalFilterData?.data}
                                    setData={setData}
                                    isNoData={isNoData}
                                    setIsNoData={setIsNoData}
                                    copyData={copyData}
                                    setCopyData={setCopyData}
                                    filterClients={filterClients}
                                    setFacilities={setFacilities}
                                    setFullFacilities={setFullFacilities}
                                    setClients={setClients}
                                    setSelectIds={setSelectIds}
                                    facilities={facilities}
                                    fullFacilities={fullFacilities}
                                    clients={clients}
                                    selectIds={selectIds}
                                    loading={loading}
                                    setLoading={setLoading}
                                    expandedRows={expandedRows}
                                    setExpandedRows={setExpandedRows}
                                    handleRowExpand={handleRowExpand}
                                    colorRow={colorRow}
                                    detailExpandedRows={detailExpandedRows}
                                    setDetailExpandedRows={setDetailExpandedRows}

                                />
                        </div>

                    </Modal.Body>
                </Modal>

            </main>
        </React.Fragment >
    )
}

export default Dashboard


import axiosInstance from './../../axiosInstance';
import {
    CREATE_CAIM_URL,
    GET_CLIENT_URL, CREATE_CLIENT_URL, CREATE_INBOUND_URL,
    CREATE_DOCUMENTTYPES_URL,
    CREATE_DEFAULT_URL, CREATE_CONTACT_PAYER_URL,
    GET_PAYER_DROPDOWN,
    TOGGLE_CLIENT_URL,
    CREATE_BLACKLISTPAYER_URL,
    CREATE_OUTBOUND_URL,
    CREATE_CLIENTS_FACILITY_URL,
    GET_CLIENTS_DETAILS_URL,
    GET_CORRESPONDENCE_CATEGORY_DROPDOWN,
    GET_REASON_CODES_DROPDOWN_URL,
    GET_CLIENTS_DROPDOWN_LISTS_URL,
    GET_QA_REVIEW_URL,
    SYNC_TO_REVARC_URL,
    GET_CLIENT_JOB_DEATIL_URL,
    GET_GRID_JOB_DETAILS,
    CREATE_DBK_URL,
    CREATE_OR_UPDATE_ADVANCE_CUSTOMISATION,
    CREATE_OPERATION_LOG_URL,
    UPDATE_JOB_USER_START_TIME,
    VERIFICATION_JOB_USER,
    CREATE_OR_UPDATE_SNIPPET,
    CREATE_OR_UPDATE_REVARC_CUSTOMISATION,
    GET_PAYER_DROPDOWNDEBOUNCE
} from './../../api/index';

// client 
export const createClient = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CLIENT_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createTat = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_TAT_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const toggleClient = (props) => onResponse => {
    try {
        axiosInstance.post(TOGGLE_CLIENT_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllClients = (page, limit, search) => onResponse => {
    try {

        let data = search ? search : ""
        axiosInstance.get(GET_CLIENT_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllClientDropdown = () => onResponse => {
    try {
        axiosInstance.get(GET_CLIENTS_DROPDOWN_LISTS_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getClientOptions = () => onResponse => {
    try {
        axiosInstance.get(GET_CLIENT_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateSnippet = (props) => onResponse => {
    // console.log(props);
    try {
        axiosInstance.post(CREATE_OR_UPDATE_SNIPPET, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getPayerDropdown = () => onResponse => {
    try {
        axiosInstance.get(GET_PAYER_DROPDOWN)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPayerDropdownForLazySearch = (props) => onResponse => {
    try {
        axiosInstance.get(GET_PAYER_DROPDOWN+'?payer_name=' + props.payer_name+'&&client_id=' + props?.client_id??"")
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const createContact = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CONTACT_PAYER_URL, props.data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};



// lock box 
export const createClaim = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CAIM_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

//inbound
export const createInBound = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_INBOUND_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

//outbound
export const createOutBound = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_OUTBOUND_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateAdvanceCustomisation = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_OR_UPDATE_ADVANCE_CUSTOMISATION, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


//defaults
export const createDefault = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_DEFAULT_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

//documentTypes
export const createDocumenttypes = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_DOCUMENTTYPES_URL, props.data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdatePayer = (client, block_id, blocked_payers) => onResponse => {
    try {
        axiosInstance.post(CREATE_BLACKLISTPAYER_URL, {
            blocked_by: client,
            block_id: block_id,
            blocked_Payer: blocked_payers
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateDbk = (data) => onResponse => {

    try {
        axiosInstance.post(CREATE_DBK_URL, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateOperationLog = (data) => onResponse => {

    try {
        axiosInstance.post(CREATE_OPERATION_LOG_URL, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getOperationLogData = () => onResponse => {

    try {
        axiosInstance.get("/operation_audit/v1/retrive-default-audit-headers")
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const postOperationLogData = (data) => onResponse => {

    try {
        axiosInstance.post("client/v2/onboard/create-or-update-operation-log-metadata", data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateFacility = (values) => onResponse => {

    try {
        axiosInstance.post(CREATE_CLIENTS_FACILITY_URL, values)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getClientDetails = (id) => onResponse => {
    try {
        axiosInstance.get(GET_CLIENTS_DETAILS_URL + '?client=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getJobDetails = (task_type, job_id, q = false) => onResponse => {
    try {
        axiosInstance.post(GET_GRID_JOB_DETAILS, { task_type, job: job_id ? job_id : "", queue: q })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {


    }

};

export const updateJobUserStartTime = (task_type, job_id) => onResponse => {
    try {
        axiosInstance.post(UPDATE_JOB_USER_START_TIME, { task_type: task_type, job: job_id ? job_id : "" })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {


    }

};

export const verificationJobUser = (task_type, job_id) => onResponse => {
    try {
        axiosInstance.get(VERIFICATION_JOB_USER + '?task_type=' + task_type + '&job=' + job_id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {


    }

};

export const getQareview = () => onResponse => {
    try {
        axiosInstance.post(GET_QA_REVIEW_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getCptDropdown = () => onResponse => {
    try {
        axiosInstance.get(GET_CORRESPONDENCE_CATEGORY_DROPDOWN)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getReasonDropdown = () => onResponse => {
    try {
        axiosInstance.get(GET_REASON_CODES_DROPDOWN_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const syncToRevArc = () => onResponse => {
    try {
        axiosInstance.post(SYNC_TO_REVARC_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getClientJobDetails = (id) => onResponse => {
    try {
        axiosInstance.get(GET_CLIENT_JOB_DEATIL_URL + '?job=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateRevarcCustomisation = (data) => onResponse => {

    try {
        axiosInstance.post(CREATE_OR_UPDATE_REVARC_CUSTOMISATION, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};